import TopStoriesWidget from "./TopStoriesWidget";
import React, { useState, useEffect, useRef } from "react";
import { IonPage, IonText, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonMenu, IonList, IonItem, IonLabel, IonIcon, IonMenuButton, IonButtons, IonButton, IonAvatar, IonRouterLink } from "@ionic/react";
import { homeOutline, walletOutline, cashOutline, trendingUpOutline, logOutOutline, logoBitcoin } from "ionicons/icons";
import "./DashboardPage.css";
import "./ui.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useAuth } from './AuthContext';
import LiveChat from './LiveChat';
import axios from 'axios';
import CryptoCompareMarquee from './CryptoCompareMarquee';
import ThemeToggle from "./ThemeToggle";
import { chatbubbleOutline } from "ionicons/icons";
import { API_BASE_URL } from '../config';



interface UserData {
  balance: number; //The available balance
}

interface RecentTransaction {
  amount: number; // The pending balance from the latest transaction
}

interface CoinSelectorCardProps {
  userData: UserData; // Contains the available balance
  recentTransactions: RecentTransaction[]; // Contains the list of recent transactions
}

const DashboardPage: React.FC = () => {
	const { logout } = useAuth();
  const [userData, setUserData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const coinMarketCapWidgetRef = useRef<HTMLDivElement | null>(null);
  const [recentTransactions, setRecentTransactions] = useState<any[]>([]);
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [currency, setCurrency] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [selectedCoin, setSelectedCoin] = useState<string>("USD"); // Default coin is USD
const [selectedCoinAvailable, setSelectedCoinAvailable] = useState<string>("USD"); // Default coin is USD for Available balance
const [selectedCoinPending, setSelectedCoinPending] = useState<string>("USD"); // Default coin is USD for Pending balance
const [selectedCoinDeposit, setSelectedCoinDeposit] = useState<string>("USD"); // Default coin is USD for Total deposit
const [selectedCoinInterest, setSelectedCoinInterest] = useState<string>("USD"); // Default coin is USD for Total Interest

const [exchangeRates, setExchangeRates] = useState<{
  BTC: number;
  ETH: number;
  LTC: number;
  USD: number;
  PI: number;
  BNB: number;
  USDT_ERC20: number;
  USDT_TRC20: number;
  XRP: number;
  SOL: number;
  MATIC: number;
  DOT: number;
  XLM: number;
  TRX: number;
  NEAR: number;
  ALGO: number;
  FIL: number;
}>({
  BTC: 1,
  ETH: 0,
  LTC: 0,
  USD: 1,
  PI: 0,
  BNB: 0,
  USDT_ERC20: 1,
  USDT_TRC20: 1,
  XRP: 0,
  SOL: 0,
  MATIC: 0,
  DOT: 0,
  XLM: 0,
  TRX: 0,
  NEAR: 0,
  ALGO: 0,
  FIL: 0,
});

// Fetching the rates logic
useEffect(() => {
  const fetchRates = async () => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,litecoin,binancecoin,usdt,xrp,solana,matic-network,polkadot,stellar,tron,near-protocol,algorand,filecoin&vs_currencies=usd"
      );
      const data = await response.json();
      setExchangeRates({
        BTC: data.bitcoin?.usd ?? 0,
        ETH: data.ethereum?.usd ?? 0,
        LTC: data.litecoin?.usd ?? 0,
        USD: 1,
        PI: data.pi?.usd ?? 0,
        BNB: data.binancecoin?.usd ?? 0,
        USDT_ERC20: data.usdt?.usd ?? 1,
        USDT_TRC20: data.usdt?.usd ?? 1,
        XRP: data.xrp?.usd ?? 0,
        SOL: data.solana?.usd ?? 0,
        MATIC: data["matic-network"]?.usd ?? 0,
        DOT: data.polkadot?.usd ?? 0,
        XLM: data.stellar?.usd ?? 0,
        TRX: data.tron?.usd ?? 0,
        NEAR: data["near-protocol"]?.usd ?? 0,
        ALGO: data.algorand?.usd ?? 0,
        FIL: data.filecoin?.usd ?? 0,
      });
    } catch (error) {
      console.error("Error fetching rates:", error);
      setExchangeRates({
        BTC: 0,
        ETH: 0,
        LTC: 0,
        USD: 1,
        PI: 0,
        BNB: 0,
        USDT_ERC20: 1,
        USDT_TRC20: 1,
        XRP: 0,
        SOL: 0,
        MATIC: 0,
        DOT: 0,
        XLM: 0,
        TRX: 0,
        NEAR: 0,
        ALGO: 0,
        FIL: 0,
      });
    }
  };

  fetchRates();
}, []); // Fetch rates on mount

// Conversion function for Available balance
const convertBalanceAvailable = (balance: number, coin: string): number => {
  switch (coin) {
    case "BTC":
      return balance * exchangeRates.BTC;
    case "ETH":
      return balance * exchangeRates.ETH;
    case "LTC":
      return balance * exchangeRates.LTC;
    case "PI":
      return balance * exchangeRates.PI;
    case "BNB":
      return balance * exchangeRates.BNB;
    case "USDT_ERC20":
      return balance * exchangeRates.USDT_ERC20;
    case "USDT_TRC20":
      return balance * exchangeRates.USDT_TRC20;
    case "XRP":
      return balance * exchangeRates.XRP;
    case "SOL":
      return balance * exchangeRates.SOL;
    case "MATIC":
      return balance * exchangeRates.MATIC;
    case "DOT":
      return balance * exchangeRates.DOT;
    case "XLM":
      return balance * exchangeRates.XLM;
    case "TRX":
      return balance * exchangeRates.TRX;
    case "NEAR":
      return balance * exchangeRates.NEAR;
    case "ALGO":
      return balance * exchangeRates.ALGO;
    case "FIL":
      return balance * exchangeRates.FIL;
    case "USD":
      return balance * exchangeRates.USD;
    default:
      return balance;
  }
};

// Conversion function for Pending balance (with different selected coin)
const convertBalancePending = (balance: number, coin: string): number => {
  switch (coin) {
    case "BTC":
      return balance * exchangeRates.BTC;
    case "ETH":
      return balance * exchangeRates.ETH;
    case "LTC":
      return balance * exchangeRates.LTC;
    case "PI":
      return balance * exchangeRates.PI;
    case "BNB":
      return balance * exchangeRates.BNB;
    case "USDT_ERC20":
      return balance * exchangeRates.USDT_ERC20;
    case "USDT_TRC20":
      return balance * exchangeRates.USDT_TRC20;
    case "XRP":
      return balance * exchangeRates.XRP;
    case "SOL":
      return balance * exchangeRates.SOL;
    case "MATIC":
      return balance * exchangeRates.MATIC;
    case "DOT":
      return balance * exchangeRates.DOT;
    case "XLM":
      return balance * exchangeRates.XLM;
    case "TRX":
      return balance * exchangeRates.TRX;
    case "NEAR":
      return balance * exchangeRates.NEAR;
    case "ALGO":
      return balance * exchangeRates.ALGO;
    case "FIL":
      return balance * exchangeRates.FIL;
    case "USD":
      return balance * exchangeRates.USD;
    default:
      return balance;
  }
};

// Conversion function for Toatl deposit (with different selected coin)
const convertBalanceDeposit = (balance: number, coin: string): number => {
  switch (coin) {
    case "BTC":
      return balance * exchangeRates.BTC;
    case "ETH":
      return balance * exchangeRates.ETH;
    case "LTC":
      return balance * exchangeRates.LTC;
    case "PI":
      return balance * exchangeRates.PI;
    case "BNB":
      return balance * exchangeRates.BNB;
    case "USDT_ERC20":
      return balance * exchangeRates.USDT_ERC20;
    case "USDT_TRC20":
      return balance * exchangeRates.USDT_TRC20;
    case "XRP":
      return balance * exchangeRates.XRP;
    case "SOL":
      return balance * exchangeRates.SOL;
    case "MATIC":
      return balance * exchangeRates.MATIC;
    case "DOT":
      return balance * exchangeRates.DOT;
    case "XLM":
      return balance * exchangeRates.XLM;
    case "TRX":
      return balance * exchangeRates.TRX;
    case "NEAR":
      return balance * exchangeRates.NEAR;
    case "ALGO":
      return balance * exchangeRates.ALGO;
    case "FIL":
      return balance * exchangeRates.FIL;
    case "USD":
      return balance * exchangeRates.USD;
    default:
      return balance;
  }
};

// Conversion function for Total Interest (with different selected coin)
const convertBalanceInterest = (balance: number, coin: string): number => {
  switch (coin) {
    case "BTC":
      return balance * exchangeRates.BTC;
    case "ETH":
      return balance * exchangeRates.ETH;
    case "LTC":
      return balance * exchangeRates.LTC;
    case "PI":
      return balance * exchangeRates.PI;
    case "BNB":
      return balance * exchangeRates.BNB;
    case "USDT_ERC20":
      return balance * exchangeRates.USDT_ERC20;
    case "USDT_TRC20":
      return balance * exchangeRates.USDT_TRC20;
    case "XRP":
      return balance * exchangeRates.XRP;
    case "SOL":
      return balance * exchangeRates.SOL;
    case "MATIC":
      return balance * exchangeRates.MATIC;
    case "DOT":
      return balance * exchangeRates.DOT;
    case "XLM":
      return balance * exchangeRates.XLM;
    case "TRX":
      return balance * exchangeRates.TRX;
    case "NEAR":
      return balance * exchangeRates.NEAR;
    case "ALGO":
      return balance * exchangeRates.ALGO;
    case "FIL":
      return balance * exchangeRates.FIL;
    case "USD":
      return balance * exchangeRates.USD;
    default:
      return balance;
  }
};


const convertedAvailableBalance = userData?.balance !== undefined ? convertBalanceAvailable(userData.balance, selectedCoinAvailable) : 0;
const convertedDepositBalance = userData?.deposit !== undefined ? convertBalanceDeposit(userData.deposit, selectedCoinDeposit) : 0;
const convertedInterestBalance = userData?.profit !== undefined ? convertBalanceInterest(userData.profit, selectedCoinInterest) : 0;
const convertedPendingBalance = recentTransactions.length > 0 && recentTransactions[0].amount !== undefined
  ? convertBalancePending(recentTransactions[0].amount, selectedCoinPending)
  : 0;
  
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get user ID from localStorage (or any other method)

        if (userId) {
          const response = await axios.get(`${API_BASE_URL}/api/user?userId=${userId}`);
          
          if (response.data && response.data.profile_image) {
            // Set the full URL for the profile picture
            setProfilePicture(`${API_BASE_URL}/${response.data.profile_image}`);
          }

          setUserData(response.data);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []); 
  // Fetch user data on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        if (userId) {
          const response = await fetch(`${API_BASE_URL}/api/user?userId=${userId}`);
          const data = await response.json();
          setUserData(data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setIsLoading(false);
      }
    };
	fetchUserData();
  }, []);
  
  // Fetch recent transactions on component mount
  useEffect(() => {
    const fetchTransactions = async () => {
      const userId = localStorage.getItem('userId');
      const response = await fetch(`${API_BASE_URL}/api/transactions?userId=${userId}`);
      const data = await response.json();
      setRecentTransactions(data); // Set the transactions
    };

    fetchTransactions();
  }, []);

  // Handle withdrawal request
  const handleWithdraw = async () => {
    const userId = localStorage.getItem('userId');
    const response = await fetch('${API_BASE_URL}/api/transactions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        currency,
        amount,
        walletAddress,
        status: 'pending', // Adjust status as per your logic
      }),
    });

    if (response.ok) {
      console.log('Withdrawal request submitted successfully');
      
      // Optionally update the recent transactions state
      setRecentTransactions((prevTransactions) => [
        { currency, amount, walletAddress, status: 'pending', date: new Date().toLocaleString() },
        ...prevTransactions,
      ]);

      // Clear inputs after successful submission
      setCurrency('');
      setAmount('');
      setWalletAddress('');
    } else {
      console.error('Failed to submit withdrawal request');
    }
  };

  // Render the transactions table rows
  const renderTransactionsTable = () => {
    return recentTransactions.map((transaction, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{transaction.currency}</td>
        <td>{transaction.amount}</td>
        <td>{transaction.walletAddress}</td>
        <td>{transaction.status}</td>
        <td>{transaction.date}</td>
      </tr>
    ));
  };
  
  // Load user data from API (fetch from MySQL database)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get the userId from localStorage
        console.log('Retrieved userId from localStorage:', userId); // Log the userId for debugging

        if (userId) {
          const response = await fetch(`${API_BASE_URL}/api/user?userId=${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const data = await response.json();
          setUserData(data);
          setIsLoading(false);
        } else {
          console.log("User ID not found in localStorage");
          setIsLoading(false);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Error fetching user data:', error.message);
        } else {
          console.error('An unknown error occurred');
        }
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Load TradingView and CoinMarketCap widgets

useEffect(() => {
  // Ensure the element exists before initializing TradingView
  const intervalId = setInterval(() => {
    const widgetContainer = document.getElementById('tradingview-market-overview');
    if (widgetContainer) {
      // Load TradingView script dynamically
      const tradingViewScript = document.createElement('script');
      tradingViewScript.src = 'https://s3.tradingview.com/tv.js';
      tradingViewScript.async = true;
      
      tradingViewScript.onload = () => {
        // Now that the script is loaded, initialize the widget
        new window.TradingView.widget({
          container_id: 'tradingview-market-overview',  // Ensure container_id is the same as the div ID
          width: '100%',
          height: '500',
          symbol: 'NASDAQ:AAPL',
          interval: '1D',
          timezone: 'Etc/UTC',
          theme: 'light',
          locale: 'en',
          style: '3',  // Candlestick chart style
          toolbar_bg: '#f1f3f6',
          enable_publishing: false,
          allow_symbol_change: true,
          details: true,
          hotlist: true,
          calendar: true
        });
      };

      document.body.appendChild(tradingViewScript);
      clearInterval(intervalId);  // Clear the interval once the script is loaded

    }
  }, 100); // Check every 100ms until the div is available in the DOM

  return () => {
    clearInterval(intervalId);  // Clean up on component unmount
  };
}, []);



  // Return JSX only after the data has been loaded
if (isLoading) {
  return (
    <div className="loading-container">
      <div className="bouncing-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

if (!userData || !userData.first_name) {
  return (
    <div className="error-page">
      <h2>Oops! Something went wrong.</h2>
      <p>We couldn't fetch your data. Please try again.</p>
      <button onClick={() => window.location.reload()} className="reload-btn">
        Try Again
      </button>
    </div>
  );
}


  return (
    <IonPage>
   <IonMenu side="start" contentId="main-content" menuId="first" type="overlay">
  <IonContent className="sidebar-content">
    <div className="sidebar-header">
      {/* Profile Avatar - Clickable to navigate to Profile */}
      <IonRouterLink routerLink="/profile">
        <IonAvatar className="profile-avatar">
  {/* Conditionally render the profile picture */}
  {profilePicture ? (
    <img src={profilePicture} alt="Profile" />
  ) : (
    <img src="https://securenodeltd.com/img/avatar.png" alt="Default Avatar" />
  )}
</IonAvatar>
      </IonRouterLink>
      <h5>{userData.username}</h5><br/>
      <h5>{userData.email}</h5><br />
    </div> <br /><br />
	<IonRouterLink routerLink="/Chat">
 &nbsp;&nbsp; <IonIcon icon={chatbubbleOutline} />&nbsp;&nbsp;  Chat Support
</IonRouterLink>

  </IonContent>
</IonMenu>

      
        
{/* Bottom Menu Bar with Icons */}
<div className="bottom-menu">
  <IonRouterLink routerLink="/dashboard">
    <IonIcon icon={homeOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/wallet">
    <IonIcon icon={walletOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/tradingbot">
    <IonIcon icon={cashOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/fundwallet">
    <IonIcon icon={logoBitcoin} />
  </IonRouterLink>
  <IonRouterLink routerLink="/market">
    <IonIcon icon={trendingUpOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/home" onClick={logout}>
    <IonIcon icon={logOutOutline} />
  </IonRouterLink>
</div>


      {/* Main Content */}
      <div className="ion-page" id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
			<IonButtons slot="end">
            <ThemeToggle />
          </IonButtons>
            <IonTitle>Dashboard</IonTitle>
          </IonToolbar>
        </IonHeader>



        <IonContent className="dashboard-content">
          {/* Greeting Section */}<br />
          <div className="greeting-section">
            <h3>Hello, {userData.username}</h3>
          </div>

          <div className="marquee">
            <h4>Enjoy Our Trade Bot as we offer 15% daily</h4>
          </div>

           {/* Key Metrics */}
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonCard className="metric-card">
                  <IonCardHeader>
                    <IonCardTitle><span>Available Balance</span></IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
               <h2>{convertedAvailableBalance.toFixed(2)}</h2>
                    <p>
  <select
    className="coin-dropdown"
    value={selectedCoinAvailable}
    onChange={(e) => setSelectedCoinAvailable(e.target.value)}
  >
    <option value="BTC">BTC</option>
    <option value="ETH">ETH</option>
    <option value="LTC">LTC</option>
    <option value="PI">PI</option>
    <option value="BNB">BNB</option>
    <option value="USDT_ERC20">USDT (ERC20)</option>
    <option value="USDT_TRC20">USDT (TRC20)</option>
    <option value="XRP">XRP</option>
    <option value="SOL">SOL</option>
    <option value="MATIC">MATIC</option>
    <option value="DOT">DOT</option>
    <option value="XLM">XLM</option>
    <option value="TRX">TRX</option>
    <option value="NEAR">NEAR</option>
    <option value="ALGO">ALGO</option>
    <option value="FIL">FIL</option>
    <option value="USD">USD</option>
  </select>
</p>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard className="metric-card">
                  <IonCardHeader>
                    <IonCardTitle><span>Total Deposit</span></IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <h1>{convertedDepositBalance.toFixed(2)}</h1>
                    <p>
  <select
    className="coin-dropdown"
    value={selectedCoinDeposit}
    onChange={(e) => setSelectedCoinDeposit(e.target.value)}
  >
    <option value="BTC">BTC</option>
    <option value="ETH">ETH</option>
    <option value="LTC">LTC</option>
    <option value="PI">PI</option>
    <option value="BNB">BNB</option>
    <option value="USDT_ERC20">USDT (ERC20)</option>
    <option value="USDT_TRC20">USDT (TRC20)</option>
    <option value="XRP">XRP</option>
    <option value="SOL">SOL</option>
    <option value="MATIC">MATIC</option>
    <option value="DOT">DOT</option>
    <option value="XLM">XLM</option>
    <option value="TRX">TRX</option>
    <option value="NEAR">NEAR</option>
    <option value="ALGO">ALGO</option>
    <option value="FIL">FIL</option>
    <option value="USD">USD</option>
  </select>
</p>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonCard className="metric-card">
                  <IonCardHeader>
                    <IonCardTitle><span>Total Interest</span></IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <h5>{convertedInterestBalance.toFixed(2)}</h5>
                    <p>
  <select
    className="coin-dropdown"
    value={selectedCoinInterest}
    onChange={(e) => setSelectedCoinInterest(e.target.value)}
  >
    <option value="BTC">BTC</option>
    <option value="ETH">ETH</option>
    <option value="LTC">LTC</option>
    <option value="PI">PI</option>
    <option value="BNB">BNB</option>
    <option value="USDT_ERC20">USDT (ERC20)</option>
    <option value="USDT_TRC20">USDT (TRC20)</option>
    <option value="XRP">XRP</option>
    <option value="SOL">SOL</option>
    <option value="MATIC">MATIC</option>
    <option value="DOT">DOT</option>
    <option value="XLM">XLM</option>
    <option value="TRX">TRX</option>
    <option value="NEAR">NEAR</option>
    <option value="ALGO">ALGO</option>
    <option value="FIL">FIL</option>
    <option value="USD">USD</option>
  </select>
</p>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard className="metric-card">
                  <IonCardHeader>
                    <IonCardTitle><span>Total Withdraw</span></IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <h4>{convertedPendingBalance.toFixed(2)}</h4>
                    <p>
  <select
    className="coin-dropdown"
    value={selectedCoinPending}
    onChange={(e) => setSelectedCoinPending(e.target.value)}
  >
    <option value="BTC">BTC</option>
    <option value="ETH">ETH</option>
    <option value="LTC">LTC</option>
    <option value="PI">PI</option>
    <option value="BNB">BNB</option>
    <option value="USDT_ERC20">USDT (ERC20)</option>
    <option value="USDT_TRC20">USDT (TRC20)</option>
    <option value="XRP">XRP</option>
    <option value="SOL">SOL</option>
    <option value="MATIC">MATIC</option>
    <option value="DOT">DOT</option>
    <option value="XLM">XLM</option>
    <option value="TRX">TRX</option>
    <option value="NEAR">NEAR</option>
    <option value="ALGO">ALGO</option>
    <option value="FIL">FIL</option>
    <option value="USD">USD</option>
  </select>
</p>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
		  <div>
		  <CryptoCompareMarquee />
			</div>
	
         <div className="chike"><h2>Wallet Overview</h2></div>
      <Swiper spaceBetween={10} slidesPerView={2} loop={true}>
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/btc.svg" // path to your Bitcoin icon
        alt="Bitcoin"
        style={{ width: "15px", height: "15px" }}
      />
      <p>BTC/USD</p>
	  <span>{userData?.overview_BTC || "0.00"}/{userData?.overview_BTC_USD || "$0.00"}</span> 
    </IonCard>
  </SwiperSlide>
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/eth.svg" // path to your Ethereum icon
        alt="Ethereum"
        style={{ width: "15px", height: "15px" }}
      />
      <p>ETH/USD</p>
	  <span>{userData?.overview_ETH || "0.00"}/{userData?.overview_ETH_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/ltc.svg" // path to your Litecoin icon
        alt="Litecoin"
        style={{ width: "15px", height: "15px" }}
      />
      <p>LTC/USD</p>
	  <span>{userData?.overview_LTC || "0.00"}/{userData?.overview_LTC_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/PI.png" // path to your Ripple icon
        alt="PI"
        style={{ width: "15px", height: "15px" }}
      />
      <p>PI/USD</p>
	  <span>{userData?.overview_PI || "0.00"}/{userData?.overview_PI_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/BNB.png" // path to your Ripple icon
        alt="BNB"
        style={{ width: "15px", height: "15px" }}
      />
      <p>BNB/USD</p>
	  <span>{userData?.overview_BNB || "0.00"}/{userData?.overview_BNB_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/USDT.svg" // path to your Ripple icon
        alt="USDT"
        style={{ width: "15px", height: "15px" }}
      />
      <p>USDT(ERC20)/USD</p>
	  <span>{userData?.overview_USDT_ERC20 || "0.00"}/{userData?.overview_USDT_USD_ERC20 || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/xrp.webp" // path to your Ripple icon
        alt="XRP"
        style={{ width: "15px", height: "15px" }}
      />
      <p>XRP/USD</p>
	  <span>{userData?.overview_XRP || "0.00"}/{userData?.overview_XRP_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/USDT.svg" // path to your Ripple icon
        alt="USDT"
        style={{ width: "15px", height: "15px" }}
      />
      <p>USDT(TRC20)/USD</p>
	  <span>{userData?.overview_USDT_TRC20 || "0.00"}/{userData?.overview_USDT_USD_TRC20 || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/SOL.webp" // path to your SOL icon
        alt="SOL"
        style={{ width: "15px", height: "15px" }}
      />
      <p>SOL/USD</p>
	  <span>0.00/$0.00</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/matic.jpg" // path to your Ripple icon
        alt="MATIC"
        style={{ width: "15px", height: "15px" }}
      />
      <p>MATIC/USD</p>
	  <span>0.00/$0.00</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/dot.png" // path to your Ripple icon
        alt="DOT"
        style={{ width: "15px", height: "15px" }}
      />
      <p>DOT/USD</p>
	  <span>0.00/$0.00</span>
    </IonCard>
  </SwiperSlide>
  
   <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/xlm.png" // path to your Ripple icon
        alt="XLM"
        style={{ width: "15px", height: "15px" }}
      />
      <p>XLM/USD</p>
	  <span>0.00/$0.00</span>
    </IonCard>
  </SwiperSlide>
  
   <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/tron.png" // path to your Ripple icon
        alt="TRX"
        style={{ width: "15px", height: "15px" }}
      />
      <p>TRX/USD</p>
	  <span>0.00/$0.00</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/near.png" // path to your Ripple icon
        alt="NEAR"
        style={{ width: "15px", height: "15px" }}
      />
      <p>NEAR/USD</p>
	  <span>0.00/$0.00</span>
    </IonCard>
  </SwiperSlide>
  
   <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/algo.avif" // path to your Ripple icon
        alt="ALGO"
        style={{ width: "15px", height: "15px" }}
      />
      <p>ALGO/USD</p>
	  <span>0.00/$0.00</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/fil.png" // path to your Ripple icon
        alt="FIL"
        style={{ width: "15px", height: "15px" }}
      />
      <p>FIL/USD</p>
	  <span>0.00/$0.00</span>
    </IonCard>
  </SwiperSlide>
  
</Swiper>
	<br />
	 <div className="scroll-wrap">
      {/* Add the Top Stories Widget */}
      <TopStoriesWidget />
    </div>
	
          {/* Trading View Widget */}
		  <IonCardHeader>
			   <div
    id="tradingview-market-overview"
    className="tradingview-widget"
    style={{ width: '100%', height: '500px' }} // Ensure the height is set
  ></div>
		  </IonCardHeader>
		  
		  {/* CoinMarketCap Widget */}
      <div ref={coinMarketCapWidgetRef} className="coinmarketcap-widget"></div>
		
		{/* Link to Live Chat */}
			{/* <LiveChat > */}
		
		  {/* Recent Transactions Section - Only display when there are transactions */}
          {recentTransactions.length > 0 && (
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle style={{ color: "gray", fontWeight: "bold" }}>
					Recent Transactions
					</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
				  <div className="recent-transactions-table">
                    <table>
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Currency</th>
                          <th>Amount</th>
                          <th>Wallet Address</th>
                          <th>Status</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {recentTransactions.map((transaction, index) => (
      <tr key={transaction.id}>
        <td>{index + 1}</td>
        <td>{transaction.currency}</td>
        <td>{transaction.amount}</td>
        <td>{transaction.walletAddress}</td>
        <td>
                      <button className={`status-btn ${transaction.status.toLowerCase()}`}>
                        {transaction.status}
                      </button>
		</td>
        <td>{new Date(transaction.createdAt).toLocaleString()}</td>
      </tr>
    ))}
                      </tbody>
                    </table>
					</div>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          )} <br/><br/><br/><br/><br/>
        </IonContent>
      </div> 
    </IonPage>
  );
};
 
 
export default DashboardPage;