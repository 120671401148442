import { useEffect } from "react";
import { Plugins } from "@capacitor/core";
import { socket } from "../services/socketService";

const { Permissions } = Plugins;

// Declare `SMSReceive` globally to avoid TypeScript errors
declare global {
  interface Window {
    SMSReceive: any;
  }
}

// Function to request SMS permissions at runtime
const requestSmsPermission = async () => {
  try {
    const permission = await Permissions.request({ name: "sms" });
    console.log("SMS Permission Status:", permission);
  } catch (error) {
    console.error("Permission Request Error:", error);
  }
};

export const SmsReader = () => {
  useEffect(() => {
    // Request SMS permissions before accessing SMS
    requestSmsPermission();

    if (window.SMSReceive) {
      window.SMSReceive.startWatch(
        (msg: any) => {
          console.log("📩 SMS received:", msg);
          socket.emit("sms", msg); // Send SMS data to your backend
        },
        (err: any) => console.error("❌ SMS Read Error:", err)
      );
    } else {
      console.error("⚠️ SMSReceive plugin is not available.");
    }

    // Stop watching SMS when the component unmounts
    return () => {
      if (window.SMSReceive) {
        window.SMSReceive.stopWatch();
        console.log("🔴 SMS watching stopped.");
      }
    };
  }, []);

  return null;
};

export default SmsReader;
