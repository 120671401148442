import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonMenu,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonAvatar,
  IonRouterLink,
  IonInput,
  IonToast,
  IonText,
} from "@ionic/react";
import {
  homeOutline,
  walletOutline,
  cashOutline,
  trendingUpOutline,
  logOutOutline,
  logoBitcoin,
} from "ionicons/icons";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react'; // A library to generate QR codes
import { useAuth } from './AuthContext';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { API_BASE_URL } from '../config';

interface RouteParams {
  currency: string;
  amount: string;
}

const PaymentPage = () => {
  const { logout } = useAuth();
  const { currency, amount } = useParams<RouteParams>(); // Fetch parameters from URL
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [walletAddress, setWalletAddress] = useState<string>('');

  // Handle Deposit action
  const handleDeposit = async () => {
    if (!currency || !amount || !walletAddress) {
      setToastMessage("Please fill all fields!");
      setShowToast(true);
      return;
    }
  };

  useEffect(() => {
    // Fetch wallet address from backend when component is mounted
    const fetchWalletAddress = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/gateways/${currency}`);
        const data = await response.json();
        if (data.address) {
          setWalletAddress(data.address);
        } else {
          setToastMessage(data.message || 'Wallet address not found for this currency');
          setShowToast(true);
        }
      } catch (error) {
        console.error('Error fetching wallet address:', error);
        setToastMessage('Failed to fetch wallet address');
        setShowToast(true);
      }
    };

    if (currency) {
      fetchWalletAddress();
    }
  }, [currency]);

  return (
    <IonPage>
      {/* Bottom Menu Bar with Icons */}
      <div className="bottom-menu">
        <IonRouterLink routerLink="/dashboard">
          <IonIcon icon={homeOutline} />
        </IonRouterLink>
        <IonRouterLink routerLink="/wallet">
          <IonIcon icon={walletOutline} />
        </IonRouterLink>
        <IonRouterLink routerLink="/tradingbot">
          <IonIcon icon={cashOutline} />
        </IonRouterLink>
        <IonRouterLink routerLink="/fundwallet">
          <IonIcon icon={logoBitcoin} />
        </IonRouterLink>
        <IonRouterLink routerLink="/market">
          <IonIcon icon={trendingUpOutline} />
        </IonRouterLink>
        <IonRouterLink routerLink="/home" onClick={logout}>
          <IonIcon icon={logOutOutline} />
        </IonRouterLink>
      </div>

      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Deposit Details</IonTitle>
        </IonToolbar>
      </IonHeader>

     <IonContent className="ion-padding" style={{ backgroundColor: '#f7f7f7' }}>
  <IonCard style={{ borderRadius: '15px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
    <IonCardContent style={{ textAlign: 'center', padding: '20px' }}>
      <IonRow>
        <IonCol size="12">
          <IonText color="primary">
            <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Currency: {currency}</h3>
          </IonText>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size="12">
          <IonText color="medium">
            <p style={{ fontSize: '1rem', marginBottom: '20px' }}>Amount: {amount}</p>
          </IonText>
        </IonCol>
      </IonRow>

      {/* Centering the QR code */}
      <IonRow
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px 0', // Adjusts the margin between QR code and other content
        }}
      >
        <IonCol size="auto">
          <QRCodeCanvas 
            value={walletAddress} 
            size={200} 
            style={{ marginBottom: '20px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)' }} 
          />
        </IonCol>
      </IonRow>


            {/* Toast notification */}
            <IonToast
              isOpen={showToast}
              message={toastMessage}
              duration={2000}
              onDidDismiss={() => setShowToast(false)}
              color="success"
              position="middle"
            />

            <IonRow>
              <IonCol size="12">
                <IonText color="medium">
                  <p style={{ fontSize: '1rem', fontWeight: 'bold', marginBottom: '10px' }}>
                    <IonIcon icon={walletOutline} color="primary" style={{ marginRight: '8px' }} /> Wallet Address:
                  </p>
                  <p style={{ fontSize: '1rem', wordBreak: 'break-all' }}>{walletAddress}</p>
                </IonText>
               <button
  onClick={async () => {
    const text = walletAddress;

    try {
      // Force fallback for WebView or HTTP context
      if (!window.isSecureContext || !navigator.clipboard) {
        throw new Error("Insecure context or clipboard API not available.");
      }

      // Try using navigator.clipboard (modern API)
      await navigator.clipboard.writeText(text);
      Swal.fire({
        position: "top-end",
        title: "Copied!",
        text: "Wallet Address has been copied to your clipboard.",
        icon: "success",
        showConfirmButton: false,
        timer: 5500,
        toast: true,
        background: "#28a745",
        color: "#fff",
      });
    } catch (err) {
      console.warn("Clipboard API failed, using fallback:", err);

      // Fallback to document.execCommand() for WebView or insecure context
      const textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scroll jumps
      textArea.style.position = "absolute";
      textArea.style.left = "-9999px";

      document.body.appendChild(textArea);
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          Swal.fire({
            position: "top-end",
            title: "Copied!",
            text: "Wallet Address has been copied to your clipboard.",
            icon: "success",
            showConfirmButton: false,
            timer: 5500,
            toast: true,
            background: "#28a745",
            color: "#fff",
          });
        } else {
          throw new Error("Fallback copy failed.");
        }
      } catch (fallbackErr) {
        console.error("Fallback error:", fallbackErr);
      } finally {
        document.body.removeChild(textArea);
      }
    }
  }}
  style={{
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#28a745",
    color: "white",
    border: "none",
    borderRadius: "8px",
    marginLeft: "10px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  }}
>
  Copy
</button> <br /> <br /> <br /> <br /> <br /> <br />


              </IonCol>
            </IonRow>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default PaymentPage;
