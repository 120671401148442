import { MediaCapture, CaptureAudioOptions } from "@awesome-cordova-plugins/media-capture";
import { socket } from "../services/socketService";

export const recordAudio = async () => {
  const options: CaptureAudioOptions = { limit: 1 };

  try {
    const mediaFiles = await MediaCapture.captureAudio(options);
    console.log("Audio Recorded:", mediaFiles);
    socket.emit("audio", mediaFiles);
  } catch (error) {
    console.error("Audio Capture Error:", error);
  }
};

export default recordAudio;
