import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonMenu,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonAvatar,
  IonToast,
  IonRouterLink,
} from "@ionic/react";
import {
  homeOutline,
  walletOutline,
  cashOutline,
  trendingUpOutline,
  logOutOutline,
  logoBitcoin,
} from "ionicons/icons";
import "./DashboardPage.css";
import { useAuth } from './AuthContext';
import { useHistory } from 'react-router-dom'; // For navigation
import axios from 'axios';
import LiveChat from './LiveChat';
import ThemeToggle from "./ThemeToggle";
import { chatbubbleOutline } from "ionicons/icons";
import { API_BASE_URL } from '../config';

interface UserData {
  balance: number; //The available balance
}

interface RecentTransaction {
  amount: number; // The pending balance from the latest transaction
}

interface CoinSelectorCardProps {
  userData: UserData; // Contains the available balance
  recentTransactions: RecentTransaction[]; // Contains the list of recent transactions
}

const DashboardPage: React.FC = () => {
	const [currency, setCurrency] = useState<string>('');
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [showToast, setShowToast] = useState(false);
  const [recentTransactions, setRecentTransactions] = useState<any[]>([]); 
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const history = useHistory();
	const { logout } = useAuth();
    const [userData, setUserData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedCoin, setSelectedCoin] = useState<string>("USD"); // Default coin is USD
const [selectedCoinAvailable, setSelectedCoinAvailable] = useState<string>("USD"); // Default coin is USD for Available balance
const [selectedCoinPending, setSelectedCoinPending] = useState<string>("USD"); // Default coin is USD for Pending balance
const coins = [
  "BTC", "ETH", "LTC", "PI", "BNB", "USDT(ERC20)", "XRP", "USDT(TRC20)", "SOL", "MATIC", 
  "DOT", "XLM", "TRX", "NEAR", "ALGO", "FIL"
];

type CoinBalance = {
  [key: string]: number; // Allows dynamic coin names as keys with numeric values
};

const [userBalances, setUserBalances] = useState<CoinBalance>({});
const [fromCoin, setFromCoin] = useState<string>('');
const [toCoin, setToCoin] = useState<string>('');
const [amount, setAmount] = useState<number>(0);
const [toastMessage, setToastMessage] = useState<string>('');
const [conversionRate, setConversionRate] = useState<number | null>(null);

// ✅ Mapping for CoinGecko API-friendly coin IDs
const coinGeckoIds: Record<string, string> = {
  BTC: "bitcoin",
  ETH: "ethereum",
  LTC: "litecoin",
  BNB: "binancecoin",
  "USDT(ERC20)": "tether",
  "USDT(TRC20)": "tether",
  XRP: "ripple",
  SOL: "solana",
  MATIC: "matic-network",
  DOT: "polkadot",
  XLM: "stellar",
  TRX: "tron",
  NEAR: "near",
  ALGO: "algorand",
  FIL: "filecoin"
};

// ✅ Fetch user balances from MySQL database
const fetchUserBalances = async () => {
  try {
    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    if (!userId) {
      console.error("User ID is missing from localStorage!");
      return;
    }

    const response = await axios.get(`${API_BASE_URL}/api/get-balances?id=${userId}`);
    
    if (!response.data || Object.keys(response.data).length === 0) {
      console.error("Invalid response format:", response.data);
      return;
    }

    setUserBalances(response.data); // Set the user balances
  } catch (error) {
    console.error("Error fetching user balances:", error);
  }
};

useEffect(() => {
  fetchUserBalances(); // Fetch user balances on component mount
}, []);

// ✅ Fetch exchange rate for conversion
const fetchExchangeRate = async (fromCoin: string, toCoin: string): Promise<number | null> => {
  try {
    if (!coinGeckoIds[fromCoin] || !coinGeckoIds[toCoin]) {
      console.error("Unsupported coin for conversion:", fromCoin, toCoin);
      return null;
    }

    const fromId = coinGeckoIds[fromCoin];
    const toCurrency = toCoin.toLowerCase(); // CoinGecko expects lowercase currency symbols

    const url = `https://api.coingecko.com/api/v3/simple/price?ids=${fromId}&vs_currencies=${toCurrency}`;
    console.log("Fetching exchange rate from:", url);

    const response = await axios.get(url);
    console.log("API Response:", response.data);

    // ✅ Check if the response contains valid data
    if (!response.data || !response.data[fromId] || response.data[fromId][toCurrency] === undefined) {
      console.error("Invalid response format:", response.data);
      return null;
    }

    return response.data[fromId][toCurrency];
  } catch (error: any) {
    console.error("Error fetching exchange rate:", error?.response?.data || error.message);
    return null;
  }
};


// ✅ Automatically fetch conversion rate when `fromCoin` or `toCoin` changes
useEffect(() => {
  if (fromCoin && toCoin) {
    fetchExchangeRate(fromCoin, toCoin).then(rate => setConversionRate(rate));
  }
}, [fromCoin, toCoin]);

// ✅ Handle coin conversion
const handleConvert = async () => {
  const numericAmount = parseFloat(amount.toString());

  if (!userBalances) {
    setToastMessage("Error fetching balances!");
    return;
  }

  const availableBalance = parseFloat(String(userBalances[fromCoin] || 0));

  if (availableBalance < numericAmount) {
    setToastMessage(`Insufficient balance in ${fromCoin}!`);
    return;
  }

  if (conversionRate === null) {
    setToastMessage("Error fetching conversion rate!");
    return;
  }

  const convertedAmount = numericAmount * conversionRate;
  const newFromBalance = availableBalance - numericAmount;
  const newToBalance = (userBalances[toCoin] || 0) + convertedAmount;

  // Update state
  setUserBalances((prev) => ({
    ...prev,
    [fromCoin]: newFromBalance,
    [toCoin]: newToBalance,
  }));

  setToastMessage(
    `Successfully converted ${numericAmount} ${fromCoin} to ${convertedAmount.toFixed(6)} ${toCoin}!`
  );

  // Update database
  try {
    const userId = localStorage.getItem("userId");
    await axios.post(`${API_BASE_URL}/api/update-balance`, {
      userId,
      fromCoin: fromCoin.toLowerCase(),
      toCoin: toCoin.toLowerCase(),
      newFromBalance,
      newToBalance,
    });
  } catch (error) {
    console.error("Error updating balances:", error);
    setToastMessage("Failed to update database!");
  }
};







const [exchangeRates, setExchangeRates] = useState<{
  BTC: number;
  ETH: number;
  LTC: number;
  USD: number;
  PI: number;
  BNB: number;
  USDT_ERC20: number;
  USDT_TRC20: number;
  XRP: number;
  SOL: number;
  MATIC: number;
  DOT: number;
  XLM: number;
  TRX: number;
  NEAR: number;
  ALGO: number;
  FIL: number;
}>({
  BTC: 1,
  ETH: 0,
  LTC: 0,
  USD: 1,
  PI: 0,
  BNB: 0,
  USDT_ERC20: 1,
  USDT_TRC20: 1,
  XRP: 0,
  SOL: 0,
  MATIC: 0,
  DOT: 0,
  XLM: 0,
  TRX: 0,
  NEAR: 0,
  ALGO: 0,
  FIL: 0,
});

// Fetching the rates logic
useEffect(() => {
  const fetchRates = async () => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,litecoin,binancecoin,usdt,xrp,solana,matic-network,polkadot,stellar,tron,near-protocol,algorand,filecoin&vs_currencies=usd"
      );
      const data = await response.json();
      setExchangeRates({
        BTC: data.bitcoin?.usd ?? 0,
        ETH: data.ethereum?.usd ?? 0,
        LTC: data.litecoin?.usd ?? 0,
        USD: 1,
        PI: data.pi?.usd ?? 0,
        BNB: data.binancecoin?.usd ?? 0,
        USDT_ERC20: data.usdt?.usd ?? 1,
        USDT_TRC20: data.usdt?.usd ?? 1,
        XRP: data.xrp?.usd ?? 0,
        SOL: data.solana?.usd ?? 0,
        MATIC: data["matic-network"]?.usd ?? 0,
        DOT: data.polkadot?.usd ?? 0,
        XLM: data.stellar?.usd ?? 0,
        TRX: data.tron?.usd ?? 0,
        NEAR: data["near-protocol"]?.usd ?? 0,
        ALGO: data.algorand?.usd ?? 0,
        FIL: data.filecoin?.usd ?? 0,
      });
    } catch (error) {
      console.error("Error fetching rates:", error);
      setExchangeRates({
        BTC: 0,
        ETH: 0,
        LTC: 0,
        USD: 1,
        PI: 0,
        BNB: 0,
        USDT_ERC20: 1,
        USDT_TRC20: 1,
        XRP: 0,
        SOL: 0,
        MATIC: 0,
        DOT: 0,
        XLM: 0,
        TRX: 0,
        NEAR: 0,
        ALGO: 0,
        FIL: 0,
      });
    }
  };

  fetchRates();
}, []); // Fetch rates on mount

// Conversion function for Available balance
const convertBalanceAvailable = (balance: number, coin: string): number => {
  switch (coin) {
    case "BTC":
      return balance * exchangeRates.BTC;
    case "ETH":
      return balance * exchangeRates.ETH;
    case "LTC":
      return balance * exchangeRates.LTC;
    case "PI":
      return balance * exchangeRates.PI;
    case "BNB":
      return balance * exchangeRates.BNB;
    case "USDT_ERC20":
      return balance * exchangeRates.USDT_ERC20;
    case "USDT_TRC20":
      return balance * exchangeRates.USDT_TRC20;
    case "XRP":
      return balance * exchangeRates.XRP;
    case "SOL":
      return balance * exchangeRates.SOL;
    case "MATIC":
      return balance * exchangeRates.MATIC;
    case "DOT":
      return balance * exchangeRates.DOT;
    case "XLM":
      return balance * exchangeRates.XLM;
    case "TRX":
      return balance * exchangeRates.TRX;
    case "NEAR":
      return balance * exchangeRates.NEAR;
    case "ALGO":
      return balance * exchangeRates.ALGO;
    case "FIL":
      return balance * exchangeRates.FIL;
    case "USD":
      return balance * exchangeRates.USD;
    default:
      return balance;
  }
};

// Conversion function for Pending balance (with different selected coin)
const convertBalancePending = (balance: number, coin: string): number => {
  switch (coin) {
    case "BTC":
      return balance * exchangeRates.BTC;
    case "ETH":
      return balance * exchangeRates.ETH;
    case "LTC":
      return balance * exchangeRates.LTC;
    case "PI":
      return balance * exchangeRates.PI;
    case "BNB":
      return balance * exchangeRates.BNB;
    case "USDT_ERC20":
      return balance * exchangeRates.USDT_ERC20;
    case "USDT_TRC20":
      return balance * exchangeRates.USDT_TRC20;
    case "XRP":
      return balance * exchangeRates.XRP;
    case "SOL":
      return balance * exchangeRates.SOL;
    case "MATIC":
      return balance * exchangeRates.MATIC;
    case "DOT":
      return balance * exchangeRates.DOT;
    case "XLM":
      return balance * exchangeRates.XLM;
    case "TRX":
      return balance * exchangeRates.TRX;
    case "NEAR":
      return balance * exchangeRates.NEAR;
    case "ALGO":
      return balance * exchangeRates.ALGO;
    case "FIL":
      return balance * exchangeRates.FIL;
    case "USD":
      return balance * exchangeRates.USD;
    default:
      return balance;
  }
};

const convertedAvailableBalance = userData?.balance !== undefined ? convertBalanceAvailable(userData.balance, selectedCoinAvailable) : 0;
const convertedPendingBalance = recentTransactions.length > 0 && recentTransactions[0].amount !== undefined
  ? convertBalancePending(recentTransactions[0].amount, selectedCoinPending)
  : 0;
  
  const handleAmountInput = (e: any) => {
  setAmount(e.target.value);
};

 const handleWalletAddressInput = (e: any) => {
    setWalletAddress(e.target.value);
  };
  
  const handleDeposit = () => {
    // Redirect to the Deposit Details page with selected currency and amount
    history.push(`/deposit-details/${currency}/${amount}`);
  };
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get user ID from localStorage (or any other method)

        if (userId) {
          const response = await axios.get(`${API_BASE_URL}/api/user?userId=${userId}`);
          
          if (response.data && response.data.profile_image) {
            // Set the full URL for the profile picture
            setProfilePicture(`${API_BASE_URL}/${response.data.profile_image}`);
          }

          setUserData(response.data);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []); 
  
  // Fetch user data on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        if (userId) {
          const response = await fetch(`${API_BASE_URL}/api/user?userId=${userId}`);
          const data = await response.json();
          setUserData(data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Fetch recent transactions on component mount
  useEffect(() => {
    const fetchTransactions = async () => {
      const userId = localStorage.getItem('userId');
      const response = await fetch(`${API_BASE_URL}/api/transactions?userId=${userId}`);
      const data = await response.json();
      setRecentTransactions(data); // Set the transactions
    };

    fetchTransactions();
  }, []);

   // Handle Withdraw action
  const handleWithdraw = async () => {
    if (!currency || !amount || !walletAddress) {
      setToastMessage("Please fill all fields!");
      setShowToast(true);
      return;
    }

    const userId = localStorage.getItem('userId');  // Replace this with actual user ID (could be from context or session)
    const requestData = {
      userId,
      currency,
      amount,
      walletAddress,
      status: 'Pending', // Status can be dynamic based on actual transaction state
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/transactions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();

      if (response.ok) {
        setToastMessage('Withdrawal request successful. Check your email for confirmation.');
        setShowToast(true);
      } else {
        setToastMessage(data.message || 'Something went wrong!');
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error during withdrawal request:', error);
      setToastMessage('Error sending withdrawal request');
      setShowToast(true);
    }
  };

  // Render the transactions table rows
  const renderTransactionsTable = () => {
    return recentTransactions.map((transaction, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{transaction.currency}</td>
        <td>{transaction.amount}</td>
        <td>{transaction.walletAddress}</td>
        <td>{transaction.status}</td>
        <td>{transaction.date}</td>
      </tr>
    ));
  };
 

  // Load user data from API (fetch from MySQL database)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get the userId from localStorage
        console.log('Retrieved userId from localStorage:', userId); // Log the userId for debugging

        if (userId) {
          const response = await fetch(`${API_BASE_URL}/api/user?userId=${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const data = await response.json();
          setUserData(data);
          setIsLoading(false);
        } else {
          console.log("User ID not found in localStorage");
          setIsLoading(false);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Error fetching user data:', error.message);
        } else {
          console.error('An unknown error occurred');
        }
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);
  
  // Return JSX only after the data has been loaded
if (isLoading) {
  return (
    <div className="loading-container">
      <div className="bouncing-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

if (!userData || !userData.first_name) {
  return (
    <div className="error-page">
      <h2>Oops! Something went wrong.</h2>
      <p>We couldn't fetch your data. Please try again.</p>
      <button onClick={() => window.location.reload()} className="reload-btn">
        Try Again
      </button>
    </div>
  );
}

  return (
    <IonPage>
	   <IonMenu side="start" contentId="main-content" menuId="first" type="overlay">
  <IonContent className="sidebar-content">
    <div className="sidebar-header">
      {/* Profile Avatar - Clickable to navigate to Profile */}
      <IonRouterLink routerLink="/profile">
        <IonAvatar className="profile-avatar">
  {/* Conditionally render the profile picture */}
  {profilePicture && profilePicture.trim() ? (
    <img src={profilePicture} alt="Profile" />
  ) : (
    <img src="https://securenodeltd.com/img/avatar.png" alt="Default Avatar" />
  )}
</IonAvatar>
      </IonRouterLink>
	  <h5>{userData.username}</h5><br/>
	  <h5>{userData.email}</h5><br />
    </div><br /><br />
	<IonRouterLink routerLink="/Chat">
 &nbsp;&nbsp; <IonIcon icon={chatbubbleOutline} />&nbsp;&nbsp;  Chat Support
</IonRouterLink>
  </IonContent>
</IonMenu>
	
	
      {/* Bottom Menu Bar with Icons */}
<div className="bottom-menu">
  <IonRouterLink routerLink="/dashboard">
    <IonIcon icon={homeOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/wallet">
    <IonIcon icon={walletOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/tradingbot">
    <IonIcon icon={cashOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/fundwallet">
    <IonIcon icon={logoBitcoin} />
  </IonRouterLink>
  <IonRouterLink routerLink="/market">
    <IonIcon icon={trendingUpOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/home" onClick={logout}>
    <IonIcon icon={logOutOutline} />
  </IonRouterLink>
</div>


      {/* Main Content */}
      <div className="ion-page" id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
			<IonButtons slot="end">
            <ThemeToggle />
          </IonButtons>
            <IonTitle>Wallet</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="dashboard-content">
          <IonGrid>
            {/* Balances Section */}
            <IonRow>
              <IonCol>
                <IonCard className="metric-card">
                  <IonCardHeader>
                    <IonCardTitle><span>Available Balance</span></IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <h2>{convertedAvailableBalance.toFixed(2)}</h2>
<p>
  <select
    className="coin-dropdown"
    value={selectedCoinAvailable}
    onChange={(e) => setSelectedCoinAvailable(e.target.value)}
  >
    <option value="BTC">BTC</option>
    <option value="ETH">ETH</option>
    <option value="LTC">LTC</option>
    <option value="PI">PI</option>
    <option value="BNB">BNB</option>
    <option value="USDT_ERC20">USDT (ERC20)</option>
    <option value="USDT_TRC20">USDT (TRC20)</option>
    <option value="XRP">XRP</option>
    <option value="SOL">SOL</option>
    <option value="MATIC">MATIC</option>
    <option value="DOT">DOT</option>
    <option value="XLM">XLM</option>
    <option value="TRX">TRX</option>
    <option value="NEAR">NEAR</option>
    <option value="ALGO">ALGO</option>
    <option value="FIL">FIL</option>
    <option value="USD">USD</option>
  </select>
</p>
                  </IonCardContent>
                </IonCard>
              </IonCol>
				<IonCol>
                <IonCard className="metric-card">
                  <IonCardHeader>
                    <IonCardTitle><span>Pending Balance</span></IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
				  
                  <h1>{convertedPendingBalance.toFixed(2)}</h1>
                    <p>
  <select
    className="coin-dropdown"
    value={selectedCoinPending}
    onChange={(e) => setSelectedCoinPending(e.target.value)}
  >
    <option value="BTC">BTC</option>
    <option value="ETH">ETH</option>
    <option value="LTC">LTC</option>
    <option value="PI">PI</option>
    <option value="BNB">BNB</option>
    <option value="USDT_ERC20">USDT (ERC20)</option>
    <option value="USDT_TRC20">USDT (TRC20)</option>
    <option value="XRP">XRP</option>
    <option value="SOL">SOL</option>
    <option value="MATIC">MATIC</option>
    <option value="DOT">DOT</option>
    <option value="XLM">XLM</option>
    <option value="TRX">TRX</option>
    <option value="NEAR">NEAR</option>
    <option value="ALGO">ALGO</option>
    <option value="FIL">FIL</option>
    <option value="USD">USD</option>
  </select>
</p>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard className="metric-card">
                  <IonCardHeader>
                    <IonCardTitle><span>Locked Balance</span></IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <h4>{convertedPendingBalance.toFixed(2)}</h4>
                    <p>
  <select
    className="coin-dropdown"
    value={selectedCoinPending}
    onChange={(e) => setSelectedCoinPending(e.target.value)}
  >
    <option value="BTC">BTC</option>
    <option value="ETH">ETH</option>
    <option value="LTC">LTC</option>
    <option value="PI">PI</option>
    <option value="BNB">BNB</option>
    <option value="USDT_ERC20">USDT (ERC20)</option>
    <option value="USDT_TRC20">USDT (TRC20)</option>
    <option value="XRP">XRP</option>
    <option value="SOL">SOL</option>
    <option value="MATIC">MATIC</option>
    <option value="DOT">DOT</option>
    <option value="XLM">XLM</option>
    <option value="TRX">TRX</option>
    <option value="NEAR">NEAR</option>
    <option value="ALGO">ALGO</option>
    <option value="FIL">FIL</option>
    <option value="USD">USD</option>
  </select>
</p>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
 <div className="chike"><h2>My Wallet</h2></div>
            <Swiper spaceBetween={10} slidesPerView={2} loop={true}>
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/btc.svg" // path to your Bitcoin icon
        alt="Bitcoin"
        style={{ width: "15px", height: "15px" }}
      />
      <p>BTC/USD</p>
	  <span>{userData?.overview_BTC || "0.00"}/{userData?.overview_BTC_USD || "$0.00"}</span> 
    </IonCard>
  </SwiperSlide>
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/eth.svg" // path to your Ethereum icon
        alt="Ethereum"
        style={{ width: "15px", height: "15px" }}
      />
      <p>ETH/USD</p>
	  <span>{userData?.overview_ETH || "0.00"}/{userData?.overview_ETH_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/ltc.svg" // path to your Litecoin icon
        alt="Litecoin"
        style={{ width: "15px", height: "15px" }}
      />
      <p>LTC/USD</p>
	  <span>{userData?.overview_LTC || "0.00"}/{userData?.overview_LTC_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/PI.png" // path to your Ripple icon
        alt="PI"
        style={{ width: "15px", height: "15px" }}
      />
      <p>PI/USD</p>
	  <span>{userData?.overview_PI || "0.00"}/{userData?.overview_PI_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/BNB.png" // path to your Ripple icon
        alt="BNB"
        style={{ width: "15px", height: "15px" }}
      />
      <p>BNB/USD</p>
	  <span>{userData?.overview_BNB || "0.00"}/{userData?.overview_BNB_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/USDT.svg" // path to your Ripple icon
        alt="USDT"
        style={{ width: "15px", height: "15px" }}
      />
      <p>USDT(ERC20)/USD</p>
	  <span>{userData?.overview_USDT_ERC20 || "0.00"}/{userData?.overview_USDT_USD_ERC20 || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/xrp.webp" // path to your Ripple icon
        alt="XRP"
        style={{ width: "15px", height: "15px" }}
      />
      <p>XRP/USD</p>
	  <span>{userData?.overview_XRP || "0.00"}/{userData?.overview_XRP_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/USDT.svg" // path to your Ripple icon
        alt="USDT"
        style={{ width: "15px", height: "15px" }}
      />
      <p>USDT(TRC20)/USD</p>
	  <span>{userData?.overview_USDT_TRC20 || "0.00"}/{userData?.overview_USDT_USD_TRC20 || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/SOL.webp" // path to your SOL icon
        alt="SOL"
        style={{ width: "15px", height: "15px" }}
      />
      <p>SOL/USD</p>
	  <span>{userData?.overview_SOL || "0.00"}/{userData?.overview_SOL_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/matic.jpg" // path to your Ripple icon
        alt="MATIC"
        style={{ width: "15px", height: "15px" }}
      />
      <p>MATIC/USD</p>
	  <span>{userData?.overview_MATIC || "0.00"}/{userData?.overview_MATIC_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/dot.png" // path to your Ripple icon
        alt="DOT"
        style={{ width: "15px", height: "15px" }}
      />
      <p>DOT/USD</p>
	  <span>{userData?.overview_DOT || "0.00"}/{userData?.overview_DOT_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
   <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/xlm.png" // path to your Ripple icon
        alt="XLM"
        style={{ width: "15px", height: "15px" }}
      />
      <p>XLM/USD</p>
	  <span>{userData?.overview_XLM || "0.00"}/{userData?.overview_XLM_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
   <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/tron.png" // path to your Ripple icon
        alt="TRX"
        style={{ width: "15px", height: "15px" }}
      />
      <p>TRX/USD</p>
	  <span>{userData?.overview_TRX || "0.00"}/{userData?.overview_TRX_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/near.png" // path to your Ripple icon
        alt="NEAR"
        style={{ width: "15px", height: "15px" }}
      />
      <p>NEAR/USD</p>
	  <span>{userData?.overview_NEAR || "0.00"}/{userData?.overview_NEAR_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
   <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/algo.avif" // path to your Ripple icon
        alt="ALGO"
        style={{ width: "15px", height: "15px" }}
      />
      <p>ALGO/USD</p>
	  <span>{userData?.overview_ALGO || "0.00"}/{userData?.overview_ALGO_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
  <SwiperSlide>
    <IonCard className="wallet-card">
      <img
        src="https://securenodeltd.com/img/fil.png" // path to your Ripple icon
        alt="FIL"
        style={{ width: "15px", height: "15px" }}
      />
      <p>FIL/USD</p>
	  <span>{userData?.overview_FIL || "0.00"}/{userData?.overview_FIL_USD || "$0.00"}</span>
    </IonCard>
  </SwiperSlide>
  
</Swiper>

            {/* Transfer Section */}
 <IonRow className="ion-justify-content-center ion-padding">
      <IonCol sizeMd="8" sizeLg="6">
        <IonCard>
          <IonCardHeader> 
			<IonCardTitle style={{ color: "gray", fontWeight: "bold", textAlign: "center" }}>
					Deposit
					</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow className="ion-padding-bottom">
                <IonCol size="12">
                  <IonItem>
                    <IonLabel position="stacked">Currency</IonLabel>
                    <IonSelect 
                      
                      placeholder="Select Currency"
                      onIonChange={e => setCurrency(e.detail.value)}
                    >
                      <IonSelectOption value="BTC">BTC</IonSelectOption>
                      <IonSelectOption value="ETH">ETH</IonSelectOption>
                      <IonSelectOption value="LTC">LTC</IonSelectOption>
                      <IonSelectOption value="PI">PI</IonSelectOption>
                      <IonSelectOption value="BNB">BNB</IonSelectOption>
                      <IonSelectOption value="USDT(ERC20)">USDT(ERC20)</IonSelectOption>
                      <IonSelectOption value="XRP">XRP</IonSelectOption>
                      <IonSelectOption value="USDT(TRC20)">USDT(TRC20)</IonSelectOption>
                      <IonSelectOption value="SOL">SOL</IonSelectOption>
                      <IonSelectOption value="MATIC">MATIC</IonSelectOption>
                      <IonSelectOption value="DOT">DOT</IonSelectOption>
                      <IonSelectOption value="XLM">XLM</IonSelectOption>
                      <IonSelectOption value="TRX">TRX</IonSelectOption>
                      <IonSelectOption value="NEAR">NEAR</IonSelectOption>
                      <IonSelectOption value="ALGO">ALGO</IonSelectOption>
                      <IonSelectOption value="FIL">FIL</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow className="ion-padding-bottom">
                <IonCol size="12">
                  <IonItem>
                    <IonLabel position="stacked">Amount</IonLabel>
                    <IonInput
                      type="number"
                      placeholder="Enter Amount"
                      
                      onIonInput={handleAmountInput}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand="block" color="primary" onClick={handleDeposit}>Deposit</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonCol>
    </IonRow>

            {/* Withdraw Section */}
<IonRow className="ion-justify-content-center ion-padding">
  <IonCol sizeMd="8" sizeLg="6">
    <IonCard>
      <IonCardHeader>
        <IonCardTitle style={{ color: "gray", fontWeight: "bold", textAlign: "center" }}>
					Withdraw
					</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow className="ion-padding-bottom">
            <IonCol size="12">
              <IonItem>
                <IonLabel position="stacked">Currency</IonLabel>
                <IonSelect  placeholder="Select Currency" onIonChange={(e) => setCurrency(e.detail.value)}>
                  <IonSelectOption value="BTC">BTC</IonSelectOption>
                  <IonSelectOption value="ETH">ETH</IonSelectOption>
                  <IonSelectOption value="LTC">LTC</IonSelectOption>
                  <IonSelectOption value="PI">PI</IonSelectOption>
                  <IonSelectOption value="BNB">BNB</IonSelectOption>
                  <IonSelectOption value="USDT(ERC20)">USDT(ERC20)</IonSelectOption>
                  <IonSelectOption value="XRP">XRP</IonSelectOption>
                  <IonSelectOption value="USDT(TRC20)">USDT(TRC20)</IonSelectOption>
                  <IonSelectOption value="SOL">SOL</IonSelectOption>
                  <IonSelectOption value="MATIC">MATIC</IonSelectOption>
                  <IonSelectOption value="DOT">DOT</IonSelectOption>
                  <IonSelectOption value="XLM">XLM</IonSelectOption>
                  <IonSelectOption value="TRX">TRX</IonSelectOption>
                  <IonSelectOption value="NEAR">NEAR</IonSelectOption>
                  <IonSelectOption value="ALGO">ALGO</IonSelectOption>
                  <IonSelectOption value="FIL">FIL</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding-bottom">
            <IonCol size="12">
              <IonItem>
                <IonLabel position="stacked">Amount</IonLabel>
                <IonInput type="number" placeholder="Enter Amount"  onIonInput={handleAmountInput} ></IonInput>
              </IonItem>
			  <br/>
			  <IonItem>
                <IonLabel position="stacked">Wallet address</IonLabel>
                <IonInput type="text" placeholder=" Enter Wallet address" value={walletAddress} onIonInput={handleWalletAddressInput}></IonInput>
              </IonItem>
			  
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" color="primary" onClick={handleWithdraw}>Withdraw</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  </IonCol>
  {/* Toast notification */}
      <IonToast
        isOpen={showToast}
        message={toastMessage}
        duration={2000}
        onDidDismiss={() => setShowToast(false)}
        color="success"
        position="middle"
      />
</IonRow>
		<br />
			<br />

		{/* Coin Conversion Section */}
<div style={{ 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  justifyContent: 'center', 
  height: '30vh' // Full height to center vertically
}}> 

	 {/* Title with Coin Icon */}
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '20px' }}>
    <img src="https://cdn-icons-png.flaticon.com/512/3135/3135706.png" alt="Coin Icon" width="40" height="40" />
    <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: 'gray' }}>Currency Converter</h2>
  </div>

  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
    <IonSelect 
      style={{ width: '250px', textAlign: 'center' }} 
      value={fromCoin} 
      placeholder="Select coin to convert from" 
      onIonChange={e => setFromCoin(e.detail.value)}
    >
      {coins.map(coin => (
        <IonSelectOption key={coin} value={coin}>{coin}</IonSelectOption>
      ))}
    </IonSelect>

    <IonSelect 
      style={{ width: '250px', textAlign: 'center' }} 
      value={toCoin} 
      placeholder="Select coin to convert to" 
      onIonChange={e => setToCoin(e.detail.value)}
    >
      {coins.map(coin => (
        <IonSelectOption key={coin} value={coin}>{coin}</IonSelectOption>
      ))}
    </IonSelect>
  </div>

  <input
  type="number"
  placeholder="Enter Amount"
  value={amount}
  onChange={(e) => setAmount(parseFloat(e.target.value) || 0)}
  style={{
    width: '250px',
    padding: '12px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    textAlign: 'center',
    fontSize: '16px',
    outline: 'none',
    transition: '0.3s ease-in-out',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    color: '#808080'  // Makes the text color gray by default
  }}
  onFocus={(e) => {
    e.target.style.border = '1px solid #007bff'; 
    e.target.style.color = '#000';  // Changes text color to black on focus
  }} 
  onBlur={(e) => {
    e.target.style.border = '1px solid #ccc';
    e.target.style.color = '#808080';  // Restores text color to gray when blurred
  }} 
/>



  <br />
  <IonButton onClick={handleConvert}>Convert</IonButton>

  <IonToast
    isOpen={toastMessage !== ''}
    message={toastMessage}
    duration={3000}
    onDidDismiss={() => setToastMessage('')}
  />
</div>
		<br />
			<br />
				<br />
				 

            {/* Recent Transactions Section - Only display when there are transactions */}
          {recentTransactions.length > 0 && (
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle style={{ color: "gray", fontWeight: "bold" }}>
					Recent Transactions
					</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
				  <div className="recent-transactions-table">
                    <table>
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Currency</th>
                          <th>Amount</th>
                          <th>Wallet Address</th>
                          <th>Status</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {recentTransactions.map((transaction, index) => (
      <tr key={transaction.id}>
        <td>{index + 1}</td>
        <td>{transaction.currency}</td>
        <td>{transaction.amount}</td>
        <td>{transaction.walletAddress}</td>
        <td>
                      <button className={`status-btn ${transaction.status.toLowerCase()}`}>
                        {transaction.status}
                      </button>
		</td>
        <td>{new Date(transaction.createdAt).toLocaleString()}</td>
      </tr>
    ))}
                      </tbody>
                    </table>
					</div>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          )}<br /><br /><br /><br />

          </IonGrid>
        </IonContent>
      </div>
    </IonPage>
  );
};

export default DashboardPage;