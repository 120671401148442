import { useEffect, useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonMenu,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonAvatar,
  IonToast,
  IonRouterLink,
  IonInput,
} from "@ionic/react";
import {
  homeOutline,
  walletOutline,
  cashOutline,
  trendingUpOutline,
  logOutOutline,
  logoBitcoin,
} from "ionicons/icons";
import "./DashboardPage.css";
import { useAuth } from './AuthContext';
import { useHistory } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls
import Swal from 'sweetalert2';
import { AxiosError } from 'axios';
import ThemeToggle from "./ThemeToggle";
import { chatbubbleOutline } from "ionicons/icons";
import { API_BASE_URL } from '../config';
import LiveChat from './LiveChat';

const ProfilePage = () => {
  const { logout } = useAuth();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');  
  const [userData, setUserData] = useState<any>({}); // State to hold user data
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    phone: "",
    recoveryPhrase: [
      "class", "letter", "ball", "home", "call", "mother", "watch", "phone", "cup", "book", "laptop", "drug",
    ],
  });
  
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordChangeError, setPasswordChangeError] = useState<string | null>(null); // To show error messages

  // Handle password change functionality
  const handlePasswordChange = async () => {
    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      setPasswordChangeError('New password and confirm password do not match');
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        throw new Error('User not logged in');
      }

      // Prepare data for password update
      const passwordData = {
        userId,
        oldPassword,
        newPassword,
      };

      // Make the API request to change the password
      const response = await axios.put(`${API_BASE_URL}/api/user/change-password`, passwordData);

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Password updated successfully!',
          text: response.data.message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
        // Clear password fields after successful update
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        setPasswordChangeError('Failed to update password');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      setPasswordChangeError('Old password is incorrect');
    }
  };


  // Load user data from API (fetch from MySQL database)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get the userId from localStorage
        console.log('Retrieved userId from localStorage:', userId); // Log the userId for debugging

        if (userId) {
          const response = await fetch(`${API_BASE_URL}/api/user?userId=${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const data = await response.json();
          setUserData(data);
          setIsLoading(false);
        } else {
          console.log("User ID not found in localStorage");
          setIsLoading(false);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Error fetching user data:', error.message);
        } else {
          console.error('An unknown error occurred');
        }
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  type ProfileField = keyof typeof profile;

  const deleteProfilePicture = async () => {
    const userId = localStorage.getItem('userId');  // Retrieve userId from localStorage

    if (!userId) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'User not logged in or invalid user',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/deleteProfilePicture/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Ensure the response is valid JSON before parsing
      const responseBody = await response.json();

      if (!response.ok) {
        throw new Error(responseBody.error || 'Failed to delete profile picture');
      }

      console.log(responseBody.message);  // Log success message

      Swal.fire({
        icon: 'success',
        title: 'Profile Picture Deleted',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
    } catch (err) {
      if (err instanceof Error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.message,
          toast: true,
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'An unknown error occurred',
          toast: true,
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false,
        });
      }
    }
  };

  const handleInputChange = (e: CustomEvent, field: ProfileField) => {
    setProfile({ ...profile, [field]: e.detail.value });
  };

  const [profilePicture, setProfilePicture] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get user ID from localStorage (or any other method)

        if (userId) {
          const response = await axios.get(`${API_BASE_URL}/api/user?userId=${userId}`);
          
          if (response.data && response.data.profile_image) {
            // Set the full URL for the profile picture
            setProfilePicture(`${API_BASE_URL}/${response.data.profile_image}`);
          }

          setUserData(response.data);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  // New state to store selected file
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // This function will be triggered when a user selects a file
  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  // This function will upload the selected file when the user clicks "Upload"
  const handleProfilePictureUpload = async () => {
    if (!selectedFile) {
      Swal.fire({
        icon: 'error',
        title: 'No file selected',
        text: 'Please select a profile picture to upload.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    const formData = new FormData();
    formData.append('profilePicture', selectedFile);  // Append the selected file to FormData

    const userId = localStorage.getItem('userId'); // Ensure you get the userId

    // Ensure userId is available
    if (!userId) {
      Swal.fire({
        icon: 'error',
        title: 'User not logged in',
        text: 'Please log in first.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    formData.append('userId', userId!);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/user/upload-profile-picture`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure the correct content type is set
        },
      });

      // Assuming the backend sends the URL of the uploaded image
      const uploadedImageUrl = `${API_BASE_URL}/${response.data.filePath}`;
      setProfilePicture(uploadedImageUrl);  // Update the state with the uploaded image URL

      Swal.fire({
        icon: 'success',
        title: 'Profile picture uploaded successfully!',
        text: response.data.message,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error uploading profile picture',
        text: 'An error occurred while uploading your profile picture.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      console.error('Error uploading profile picture:', error);
    }
  };

  const handleSaveProfile = async () => {
  try {
    console.log("Profile updated:", profile);
    setToastMessage("Profile updated:");
    setShowToast(true);

    // Get the userId from localStorage to identify the user
    const userId = localStorage.getItem('userId');
    if (!userId) {
      throw new Error('User ID not found');
    }

    // Prepare the data to send
    const profileData = {
      userId, // Include the userId to update the correct user's profile
      first_name: profile.firstName, // Match snake_case field names expected by the backend
      last_name: profile.lastName,
      username: profile.username,
      email: profile.email,
      contact_number: profile.phone, // Match the field names with those expected in the DB
    };

    // Send the updated profile data to the server
    const response = await axios.put(`${API_BASE_URL}/api/user/profile`, profileData);

    if (response.status === 200) {
      console.log('Profile updated successfully');
      setToastMessage('Profile updated successfully');
      setShowToast(true);
    } else {
      console.error('Failed to update profile:', response.statusText);
      setToastMessage('Error updating profile');
      setShowToast(true);
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      // Now that we know 'error' is an instance of Error, it's safe to access `message`
      console.error('Error updating profile:', error);
      setToastMessage(`Error: ${error.message}`);
    } else {
      // In case the error is not an instance of Error, handle it gracefully
      console.error('Unknown error:', error);
      setToastMessage('An unknown error occurred.');
    }
    setShowToast(true);
  }
};


// Function to fetch user data from API
  const fetchUserData = async () => {
    try {
      const userId = localStorage.getItem('userId'); // Get the userId from localStorage
      if (userId) {
        const response = await fetch(`${API_BASE_URL}/api/user?userId=${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const data = await response.json();
        setUserData(data);
        setProfile((prevProfile) => ({
          ...prevProfile,
          firstName: data.first_name,  // Match snake_case data
          lastName: data.last_name,
          username: data.username,
          email: data.email,
          phone: data.contact_number, // Match the field names from the fetched data
        }));
        setIsLoading(false);
      } else {
        console.log("User ID not found in localStorage");
        setIsLoading(false);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error fetching user data:', error.message);
      } else {
        console.error('An unknown error occurred');
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

// Return JSX only after the data has been loaded
if (isLoading) {
  return (
    <div className="loading-container">
      <div className="bouncing-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

if (!userData || !userData.first_name) {
  return (
    <div className="error-page">
      <h2>Oops! Something went wrong.</h2>
      <p>We couldn't fetch your data. Please try again.</p>
      <button onClick={() => window.location.reload()} className="reload-btn">
        Try Again
      </button>
    </div>
  );
}

  return (
    <IonPage id="main-content">
<IonMenu side="start" contentId="main-content" menuId="first" type="overlay">
    <IonContent className="sidebar-content">
      <div className="sidebar-header">
        <IonRouterLink routerLink="/profile">
          <IonAvatar className="profile-avatar">
            {profilePicture ? (
              <img src={profilePicture} alt="Profile" />
            ) : (
              <img src="https://securenodeltd.com/img/avatar.png" alt="Default Avatar" />
            )}
          </IonAvatar>
        </IonRouterLink>
        <h5>{userData.username}</h5><br />
        <h5>{userData.email}</h5><br />
      </div><br /><br />
	<IonRouterLink routerLink="/Chat">
 &nbsp;&nbsp; <IonIcon icon={chatbubbleOutline} />&nbsp;&nbsp;  Chat Support
</IonRouterLink>
    </IonContent>
  </IonMenu>

  <IonHeader>
    <IonToolbar>
      <IonButtons slot="start">
        <IonMenuButton menu="first" />
      </IonButtons>
	  <IonButtons slot="end">
            <ThemeToggle />
          </IonButtons>
      <IonTitle>Profile Page</IonTitle>
    </IonToolbar>
  </IonHeader>
	
      {/* Bottom Menu Bar with Icons */}
      <div className="bottom-menu">
        <IonRouterLink routerLink="/dashboard">
          <IonIcon icon={homeOutline} />
        </IonRouterLink>
        <IonRouterLink routerLink="/wallet">
          <IonIcon icon={walletOutline} />
        </IonRouterLink>
        <IonRouterLink routerLink="/tradingbot">
          <IonIcon icon={cashOutline} />
        </IonRouterLink>
        <IonRouterLink routerLink="/fundwallet">
          <IonIcon icon={logoBitcoin} />
        </IonRouterLink>
        <IonRouterLink routerLink="/market">
          <IonIcon icon={trendingUpOutline} />
        </IonRouterLink>
        <IonRouterLink routerLink="/home" onClick={logout}>
          <IonIcon icon={logOutOutline} />
        </IonRouterLink>
      </div>
	
      
      <IonContent id="main-content">
        <IonGrid>
          <IonGrid>
  <IonRow>
    <IonCol size="12">
      <h3>Profile Picture</h3>
    </IonCol>
  </IonRow>

  <IonRow className="ion-align-items-center">
    <IonCol size="12" sizeMd="6">
      <IonItem>
        <input type="file" onChange={handleFileSelection} style={{ width: "100%" }} />
      </IonItem>
    </IonCol>

    <IonCol size="12" sizeMd="6">
      <IonButton expand="block" color="primary" onClick={handleProfilePictureUpload}>
        Upload
      </IonButton>
    </IonCol>
  </IonRow>

  <IonRow>
    <IonCol size="12">
      <IonButton expand="block" color="danger" onClick={deleteProfilePicture}>
        Delete Profile Picture
      </IonButton>
    </IonCol>
  </IonRow>
</IonGrid>


          {/* Show uploaded profile picture */}
          <IonRow>
            <IonCol size="12" sizeMd="6">
              {profilePicture && <IonAvatar>
                <img src={profilePicture} alt="Profile" />
              </IonAvatar>}
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <h3>Personal Details</h3>
              <IonList>
                {["firstName", "lastName", "username", "email", "phone"].map((field, index) => (
                  <IonItem key={index}>
                    <IonLabel position="floating">{field.charAt(0).toUpperCase() + field.slice(1)}</IonLabel>
                    <IonInput
                      value={userData[field as keyof typeof userData] || profile[field as keyof typeof profile]}
                      onIonChange={(e: CustomEvent) => handleInputChange(e, field as ProfileField)}
                    />
                  </IonItem>
                ))}
                <IonButton expand="block" color="primary" onClick={handleSaveProfile}>
                  Save Profile
                </IonButton>
              </IonList>
            </IonCol>
			{/* Toast notification */}
      <IonToast
        isOpen={showToast}
        message={toastMessage}
        duration={2000}
        onDidDismiss={() => setShowToast(false)}
        color="success"
        position="middle"
      />
          </IonRow>
		  
		   <h3>Security Information</h3>
              <IonList>
                <IonItem>
                  <IonLabel position="floating">Old Password</IonLabel>
                  <IonInput
                    type="password"
                    value={oldPassword}
                    onIonChange={(e: CustomEvent) => setOldPassword(e.detail.value!)}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">New Password</IonLabel>
                  <IonInput
                    type="password"
                    value={newPassword}
                    onIonChange={(e: CustomEvent) => setNewPassword(e.detail.value!)}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Confirm Password</IonLabel>
                  <IonInput
                    type="password"
                    value={confirmPassword}
                    onIonChange={(e: CustomEvent) => setConfirmPassword(e.detail.value!)}
                  />
                </IonItem>
                {passwordChangeError && (
                  <IonItem color="danger">
                    <IonLabel>{passwordChangeError}</IonLabel>
                  </IonItem>
                )}
                <IonButton expand="block" color="primary" onClick={handlePasswordChange}>
                  Change Password
                </IonButton>
              </IonList>
		  
		  {/* Recovery Phrase Section */}
          <IonRow>
            <IonCol>
              <h3>Recovery Phrase</h3>
              {profile.recoveryPhrase.map((word, index) => (
                <IonItem key={index}>
                  <IonLabel>{word}</IonLabel>
                </IonItem>
              ))}
            </IonCol>
          </IonRow> 
        </IonGrid>
      </IonContent><br /><br /><br /><br /><br />
    </IonPage>
  );
};

export default ProfilePage;
