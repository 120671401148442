import React, { createContext, useContext, ReactNode } from 'react';

// Create the context
const AuthContext = createContext<any>(null);

// Define the props interface to include 'children'
interface AuthProviderProps {
  children: ReactNode;
}

// Create a provider component that will wrap your entire app
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const logout = () => {
    // Handle logout logic, clear localStorage, and call logout API
    const rememberMe = localStorage.getItem('rememberMe') === 'true';

    if (rememberMe) {
      console.log("Remember Me is enabled, keeping the auth token.");
    } else {
      console.log("Clearing all data.");
      localStorage.clear();  // Clear all data from localStorage
    }

    // Optional: Call backend logout API to invalidate the session
    fetch('https://securenodeltd.com/logout', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Server logout successful:', data);
      })
      .catch((error) => {
        console.error('Error logging out from server:', error);
      });

    // Redirect to home page or login page after logout
    window.location.href = '/home';  // Or history.push('/home') if using React Router
  };

  return (
    <AuthContext.Provider value={{ logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access the logout function
export const useAuth = () => useContext(AuthContext);
