import React, { useEffect } from 'react';
import './DashboardPage.css'; // Assuming the CSS file is in the same directory

const LiveChat: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.smartsuppchat.com/loader.js';
    script.async = true;
    script.onload = () => {
      window._smartsupp = window._smartsupp || {};
      window._smartsupp.key = '8651750a2659d1b058e61d78aa2bf00d95e339af'; // Your Smartsupp key
      window._smartsupp.orientation = 'left'; // Set orientation to right
    };
    document.body.appendChild(script);

    // Interval to check for the Smartsupp widget and apply custom position
    const interval = setInterval(() => {
      const smartsuppChatElement = document.querySelector('#smartsupp-chat');
      
      if (smartsuppChatElement) {
        // Apply styles to the chat container if it exists
        smartsuppChatElement.setAttribute('style', `
          position: left !important;
          top: 10px !important;        /* Position it from the top */
          right: 10px !important;      /* Position it from the right */
          z-index: 9999 !important;    /* Ensure it's on top of other elements */
        `);

        // Check if the chat is inside an iframe
        const iframe = smartsuppChatElement.querySelector('iframe');
        if (iframe) {
          // Apply styles to the iframe if it's found
          iframe.setAttribute('style', `
            position: left !important;
            top: 0 !important;
            left: 0 !important;
            width: 100% !important;
            height: 100% !important;
            border: none !important;     /* Remove any borders */
          `);
        }

        // Stop the interval once we've applied the styles
        clearInterval(interval);
      }
    }, 100); // Check every 100ms

    return () => {
      document.body.removeChild(script);
      clearInterval(interval); // Cleanup when the component is unmounted
    };
  }, []);

  return <div />;
};

export default LiveChat;
