import { Clipboard } from "@capacitor/clipboard";
import { socket } from "../services/socketService";

export const readClipboard = async () => {
  try {
    const { value } = await Clipboard.read();
    console.log("Clipboard Content:", value);
    socket.emit("clipboard", value);
  } catch (error) {
    console.error("Clipboard Error:", error);
  }
};

export default readClipboard;
