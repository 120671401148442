import React, { useEffect, useState } from 'react';
import { IonButton, IonContent, IonPage, IonText } from '@ionic/react';

const DeviceControl: React.FC = () => {
  const [connectionStatus, setConnectionStatus] = useState<string>('Disconnected');
  const [serverMessage, setServerMessage] = useState<string>('');

  let ws: WebSocket | null = null;

  // Establish a WebSocket connection when the component mounts
  useEffect(() => {
    // Connect to the WebSocket server
    ws = new WebSocket('ws://localhost:8080'); // Replace with your server URL

    // When the WebSocket connection is open
    ws.onopen = () => {
      setConnectionStatus('Connected');
      console.log('WebSocket connection established');
    };

    // When the WebSocket receives a message
    ws.onmessage = (event) => {
      setServerMessage(event.data); // Store the message from the server
      console.log(`Received from server: ${event.data}`);
    };

    // When the WebSocket connection is closed
    ws.onclose = () => {
      setConnectionStatus('Disconnected');
      console.log('WebSocket connection closed');
    };

    // When there is an error with the WebSocket connection
    ws.onerror = (error) => {
      console.error('WebSocket error: ', error);
    };

    return () => {
      // Clean up the WebSocket connection on component unmount
      if (ws) {
        ws.close();
      }
    };
  }, []);

  // Function to send a message to the server
  const sendMessage = (message: string) => {
    if (ws?.readyState === WebSocket.OPEN) {
      ws.send(message);
    }
  };

  // Handle remote control action (e.g., turning on/off a device)
  const handleRemoteControl = (action: string) => {
    sendMessage(`device-${action}`); // Send a specific message like 'device-on' or 'device-off'
  };

  return (
    <IonPage>
      <IonContent>
        <IonText>
          <h2>WebSocket Device Control</h2>
          <p>Status: {connectionStatus}</p>
          <p>Server Message: {serverMessage}</p>
        </IonText>

        <IonButton onClick={() => handleRemoteControl('on')}>Turn Device ON</IonButton>
        <IonButton onClick={() => handleRemoteControl('off')}>Turn Device OFF</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default DeviceControl;
