import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonThumbnail,
  IonSpinner,
} from "@ionic/react";

interface Story {
  id: number;
  title: string;
  time: string;
  image: string;
}

const TopStoriesWidget: React.FC = () => {
  const [stories, setStories] = useState<Story[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStories = async () => {
      try {
        // Fetch all cryptocurrencies' market data using CoinGecko API
        const response = await fetch(
          "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd"
        );

        const data = await response.json();

        // Format the fetched data to match your UI structure
        const formattedStories = data.map((coin: any, index: number) => ({
          id: index + 1,
          title: `${coin.name} (${coin.symbol.toUpperCase()}) - $${coin.current_price}`,
          time: new Date().toLocaleTimeString(), // Example time, update as needed
          image: coin.image || "https://via.placeholder.com/50", // Fallback image
        }));

        setStories(formattedStories);
      } catch (error) {
        console.error("Error fetching stories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStories();
  }, []);

  return (
    <IonCard>
      <IonCardContent>
        <h2 style={{ fontWeight: "bold", color: "black" }}>Top Stories</h2>
        {loading ? (
          <IonSpinner name="lines" />
        ) : (
          <IonList>
            {stories.map((story) => (
              <IonItem key={story.id} lines="none">
                <IonThumbnail slot="start">
                  <img src={story.image} alt={story.title} />
                </IonThumbnail>
                <IonLabel>
                  <h3>{story.title}</h3>
                  <p>{story.time}</p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default TopStoriesWidget;
