import { useEffect, useState } from "react";
import {
  IonButton,
  IonIcon,
  IonPopover,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { sunny, moon, ellipsisVertical } from "ionicons/icons";
import { Preferences } from "@capacitor/preferences";

const ThemeToggle: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    const loadTheme = async () => {
      const theme = await Preferences.get({ key: "theme" });
      if (theme.value === "dark") {
        document.documentElement.classList.add("dark");
        setIsDarkMode(true);
      } else {
        document.documentElement.classList.remove("dark");
        setIsDarkMode(false);
      }
    };

    loadTheme();
  }, []);

  const toggleDarkMode = async () => {
    const newTheme = isDarkMode ? "light" : "dark";

    document.documentElement.classList.toggle("dark", newTheme === "dark");
    await Preferences.set({ key: "theme", value: newTheme });

    setIsDarkMode(newTheme === "dark");
    setShowPopover(false); // Close dropdown after selection
  };

  return (
    <>
      {/* Button to open dropdown */}
      <IonButton onClick={() => setShowPopover(true)}>
        <IonIcon icon={ellipsisVertical} />
      </IonButton>

      {/* Dropdown for dark mode toggle */}
      <IonPopover
        isOpen={showPopover}
        onDidDismiss={() => setShowPopover(false)}
      >
        <IonList>
          <IonItem button onClick={toggleDarkMode}>
            <IonIcon slot="start" icon={isDarkMode ? sunny : moon} />
            <IonLabel>{isDarkMode ? "Light Mode" : "Dark Mode"}</IonLabel>
          </IonItem>
        </IonList>
      </IonPopover>
    </>
  );
};

export default ThemeToggle;
