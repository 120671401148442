import React, { useEffect, useState } from "react";
import axios from "axios";
import "./CryptoCompareMarquee.css"; // Import the CSS

const CryptoCompareMarquee = () => {
  const [cryptoData, setCryptoData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://min-api.cryptocompare.com/data/top/mktcapfull?limit=10&tsym=USD`,
          {
            headers: {
              Authorization: `099ecd90dd87b8b9a51d54fbf8d9cd188351825b295b6d771ec3a2a4904a39dd`, // Replace with your API key
            },
          }
        );

        console.log("API Response:", response.data); // Debugging log

        if (response.data && response.data.Data) {
          setCryptoData(response.data.Data);
        } else {
          setCryptoData([]); // Set empty array if data is missing
        }
      } catch (error) {
        console.error("Error fetching crypto data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="marquee-container">
      <div className="marquee-content">
        {cryptoData.length > 0 ? (
          cryptoData.map((coin) => {
            const price = coin?.RAW?.USD?.PRICE ?? "N/A"; // Use optional chaining & default value
            return (
              <div key={coin?.CoinInfo?.Id} className="marquee-item">
                <span>
                  {coin?.CoinInfo?.FullName ?? "Unknown"} (
                  {coin?.CoinInfo?.Name ?? "N/A"}):{" "}
                  {typeof price === "number" ? `$${price.toFixed(2)}` : price}
                </span>
              </div>
            );
          })
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default CryptoCompareMarquee;
