import React, { useState } from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonInput, IonButton, IonLabel, IonItem, IonCard, IonCardHeader, IonCardContent, IonText, IonAlert } from '@ionic/react';
import './ForgotPassword.css'; // Import the custom styles for the page
import { API_BASE_URL } from '../config';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false); // To control alert visibility
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertHeader, setAlertHeader] = useState<string>('');

  const handleSubmit = async () => {
    if (!email) {
      setError('Please enter your email');
      return;
    }

    try {
      // Resetting error state
      setError('');

      // Send a request to the backend API to send the reset link
      const response = await fetch(`${API_BASE_URL}/api/password-reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        // Assuming the backend returns a success message
        setAlertHeader('Success!');
        setAlertMessage(data.message || 'A password reset link has been sent to your email.');
        setShowAlert(true);
      } else {
        setAlertHeader('Error');
        setAlertMessage(data.message || 'Failed to send reset link. Please try again.');
        setShowAlert(true);
      }
    } catch (err) {
      setAlertHeader('Error');
      setAlertMessage('Failed to send reset link. Please try again.');
      setShowAlert(true);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Forgot Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard className="forgot-password-card">
          <IonCardHeader>
            <h2>Reset Your Password</h2>
            <p className="subheading">Please enter the email address you used to sign up.</p>
          </IonCardHeader>
          <IonCardContent>
            <IonItem>
              <IonLabel position="floating">Email Address</IonLabel>
              <IonInput
                type="email"
                value={email}
                onIonChange={(e) => setEmail(e.detail.value!)}
                required
              />
            </IonItem>
            {error && <IonText color="danger"><p>{error}</p></IonText>}
            <IonButton expand="full" onClick={handleSubmit} color="primary">
              Send Reset Link
            </IonButton>
            <div className="back-to-login">
              <IonText color="medium">Remembered your password?</IonText>
              <IonButton fill="clear" href="/home">
                Back to Login
              </IonButton>
            </div>
          </IonCardContent>
        </IonCard>

        {/* Custom IonAlert for Success/Error */}
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={alertHeader}
          message={alertMessage}
          buttons={['OK']}
          cssClass="custom-alert"
        />
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
