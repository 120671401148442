import React, { useState, useEffect } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import './Home.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { API_BASE_URL } from '../config';

const formContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px',
  padding: '20px',
  borderRadius: '8px',
  backgroundColor: '#f9f9f9',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
};

const inputContainerStyle: React.CSSProperties = {
  width: '100%',
  marginBottom: '15px',
};

const inputStyle: React.CSSProperties = {
  width: '100%',
  padding: '12px 20px',
  fontSize: '16px',
  border: '2px solid #ccc',
  borderRadius: '8px',
  outline: 'none',
  transition: 'border-color 0.3s',
};

const buttonContainerStyle: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

const buttonStyle: React.CSSProperties = {
  padding: '12px 25px',
  fontSize: '16px',
  backgroundColor: '#007bff',
  color: 'white',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const SignUp: React.FC = () => {
  const [isPageLoading, setIsPageLoading] = useState(true); // Page load spinner
  const [isLoading, setIsLoading] = useState(false); // Form submit spinner
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });

  const [activationCodeSent, setActivationCodeSent] = useState(false);
  const [activationCode, setActivationCode] = useState('');
  const [userCode, setUserCode] = useState('');
  const [showRecoveryPhrase, setShowRecoveryPhrase] = useState(false);
  const [recoveryPhrase, setRecoveryPhrase] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    // Simulate a page load delay to show the spinner initially
    const timer = setTimeout(() => {
      setIsPageLoading(false); // Hide spinner after page load
    }, 1000); // You can adjust this timeout value

    return () => clearTimeout(timer);
  }, []);

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true); // Show loading spinner when submitting form

    if (userData.password !== userData.confirmPassword) {
      setIsLoading(false); // Stop loading spinner on error
      setTimeout(() => {
        Swal.fire({
          position: 'top-end',
          title: 'Error!',
          text: 'Passwords do not match. Please confirm your password.',
          icon: 'error',
          showConfirmButton: false,
          timer: 5500,
          toast: true,
          background: 'white',
          color: 'black',
        });
      }, 100);
      return;
    }

    fetch(`${API_BASE_URL}/api/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false); // Stop loading spinner
        if (data.message === 'Activation code sent to your email') {
          setActivationCodeSent(true);
        } else if (
          data.message === 'User already exists. Please use a different email or username.'
        ) {
          setTimeout(() => {
            Swal.fire({
              position: 'top-end',
              title: 'Failed!',
              text: 'User already exists. Please use a different email or username.',
              icon: 'error',
              showConfirmButton: false,
              timer: 5500,
              toast: true,
              background: 'white',
              color: 'black',
            });
          }, 100);
        } else {
          setErrorMessage('Failed to register. Please try again later.');
        }
      })
      .catch((error) => {
        setIsLoading(false); // Stop loading spinner
        setErrorMessage('Error occurred during registration. Please try again.');
        console.error('Error registering:', error);
      });
  };

  const handleCodeVerification = (e: React.FormEvent) => {
    e.preventDefault();

    console.log('Entered code:', userCode); // Log the code entered by the user
    console.log('Activation code (from state):', activationCode); // Log the code from state

    fetch(`${API_BASE_URL}/api/verify-code`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: userData.email, code: userCode, userData }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'Activation successful!') {
          setTimeout(() => {
            Swal.fire({
              position: 'top-end',
              title: 'Registration Successful!',
              text: 'Your account has been successfully created.',
              icon: 'success',
              confirmButtonText: 'OK',
              timer: 5500,
              toast: true,
              background: 'white',
              color: 'black',
            }).then(() => {
              fetchRecoveryPhrase();
            });
          }, 100);
        } else {
          setErrorMessage('Failed to verify activation code! Please try again.');
        }
      })
      .catch((error) => {
        setErrorMessage('Error verifying the code. Please try again later.');
        console.error('Error verifying code:', error);
      });
  };

  const fetchRecoveryPhrase = () => {
    fetch(`${API_BASE_URL}/api/recovery-phrase`)
      .then((response) => response.json())
      .then((data) => {
        setRecoveryPhrase(data.recoveryPhrase);
        setShowRecoveryPhrase(true);
      })
      .catch((error) => {
        setErrorMessage('Error fetching recovery phrase. Please try again.');
        console.error('Error fetching recovery phrase:', error);
      });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        {isPageLoading || isLoading ? (
          // Loading spinner visible if the page is loading or form is submitting
          <div id="preloader">
            <div id="status">
              <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            </div>
          </div>
        ) : (
          <div className="login-container">
            {errorMessage && (
              <div className="error-message">
                <p>{errorMessage}</p>
              </div>
            )}

            {!activationCodeSent ? (
              <form className="login-form" onSubmit={handleFormSubmit}>
                <div className="logo">
                  <img
                    src="https://securenodeltd.com/img/key.png"
                    alt="SecureNode Logo"
                  />
                </div>
                <p>Create an account</p>
                <input
                  type="text"
                  placeholder="First Name"
                  className="input-field"
                  required
                  onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="input-field"
                  required
                  onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                />
                <input
                  type="text"
                  placeholder="User Name"
                  className="input-field"
                  required
                  onChange={(e) => setUserData({ ...userData, username: e.target.value })}
                />
                <input
                  type="email"
                  placeholder="Email Address"
                  className="input-field"
                  required
                  onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                />
                <input
                  type="number"
                  placeholder="Phone Number"
                  className="input-field"
                  required
                  onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                />
                <input
                  type="password"
                  placeholder="Password"
                  className="input-field"
                  required
                  onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="input-field"
                  required
                  onChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
                />
                <button type="submit" className="login-button">
                  Create Wallet
                </button>
                <div className="signup-and-copyright">
                  <p className="signup-link">
                    <span>Already have an account?</span>&nbsp;
                    <a href="/home" className="sign-up">Sign in</a>
                  </p>
                  <p className="copyright">
                    Copyright © 2025 Secure Node Ltd
                  </p>
                </div>
              </form>
            ) : (
              <form onSubmit={handleCodeVerification} style={formContainerStyle}>
                <div style={inputContainerStyle}>
                  <p>Please check your email for an activation code.</p>
                  <input
                    type="text"
                    placeholder="Enter Activation Code"
                    value={userCode}
                    onChange={(e) => setUserCode(e.target.value)}
                    style={inputStyle}
                  />
                </div>
                <div style={buttonContainerStyle}>
                  <button type="submit" style={buttonStyle}>
                    Verify
                  </button>
                </div>
              </form>
            )}<br/>
            {showRecoveryPhrase && (
  <div>
    <div
      style={{
        backgroundColor: '#eafaf1',
        padding: '20px',
        borderRadius: '8px',
        marginBottom: '20px',
      }}
    >
      <p
        style={{
          fontSize: '18px',
          fontWeight: '600',
          color: '#28a745',
          marginBottom: '10px',
        }}
      >
        Registration successful! Here is your recovery key:
      </p>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <pre
          style={{
            backgroundColor: '#f4f4f4',
            borderRadius: '8px',
            padding: '15px',
            fontSize: '16px',
            fontFamily: 'monospace',
            color: '#333',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            margin: '0',
            maxWidth: '100%',
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            flex: '1 1 0px', // Ensures it takes up available space
            maxHeight: '200px', // Prevents overflow
            overflow: 'auto', // Adds scroll if content overflows
          }}
        >
          {recoveryPhrase}
        </pre>
        <button
          onClick={() => {
            navigator.clipboard.writeText(recoveryPhrase);
            Swal.fire({
              position: 'top-end',
              title: 'Copied!',
              text: 'Recovery key has been copied to your clipboard.',
              icon: 'success',
              showConfirmButton: false,
              timer: 5500,
              toast: true,
              background: '#28a745',
              color: '#fff',
            });
          }}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#28a745',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            marginLeft: '10px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            whiteSpace: 'nowrap', // Ensures text doesn’t wrap inside button
          }}
        >
          Copy
        </button>
      </div>
    </div>

    {/* Add a Login button that redirects to the login page */}
    <div style={{ marginTop: '20px' }}>
      <button
        onClick={() => (window.location.href = '/home')}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          width: '100%', // Makes the button stretch on small screens
          boxSizing: 'border-box',
        }}
      >
        Go to Login
      </button>
    </div>
  </div>
)}

{/* Media Query Styling */}
<style>
  {`
    @media (max-width: 600px) {
      pre {
        font-size: 14px; /* Reduce font size on smaller screens */
        padding: 10px;
      }

      button {
        font-size: 14px; /* Adjust button size on smaller screens */
        padding: 8px 16px;
      }
    }
  `}
</style>

          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default SignUp;
