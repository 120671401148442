import React, { useEffect, useState } from 'react';
import { IonPage, IonContent, IonList, IonItem } from '@ionic/react';
import { Plugins } from '@capacitor/core';

const { SMSReader } = Plugins;

// Define the expected structure of an SMS message
interface SMSMessage {
    sender: string;
    body: string;
}

// Ensure TypeScript recognizes the structure of messages
const SMSLogs: React.FC = () => {
    const [messages, setMessages] = useState<SMSMessage[]>([]);

    useEffect(() => {
        SMSReader.getMessages()
            .then((res: { messages: SMSMessage[] }) => setMessages(res.messages))
            .catch(console.error);
    }, []);

    return (
        <IonPage>
            <IonContent>
                <IonList>
                    {messages.map((msg, index) => (
                        <IonItem key={index}>{msg.sender}: {msg.body}</IonItem>
                    ))}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default SMSLogs;
