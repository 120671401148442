import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonItem,
  IonList,
  IonText,
  IonMenu,
  IonRouterLink,
  IonAvatar,
  IonIcon,
  IonMenuToggle,
  IonMenuButton,
  IonButtons,
  IonToast,
} from "@ionic/react";
import {
  homeOutline,
  walletOutline,
  cashOutline,
  logoBitcoin,
  trendingUpOutline,
  logOutOutline,
  menuOutline,
} from "ionicons/icons";
import "./DashboardPage.css"; // Optional: Add a CSS file for custom styles.
import { useAuth } from './AuthContext';
import ThemeToggle from "./ThemeToggle";
import { chatbubbleOutline } from "ionicons/icons";
import { API_BASE_URL } from '../config';

const TradingViewChart: React.FC = () => {
const chartRef = useRef<HTMLDivElement>(null);

   useEffect(() => {
    if (!chartRef.current) {
      console.error("Chart container not found!");
      return;
    }

    // Clear previous content
    chartRef.current.innerHTML = "";

    // Ensure TradingView is available
    if (typeof window !== "undefined" && (window as any).TradingView) {
      new (window as any).TradingView.widget({
        container_id: chartRef.current.id,
        width: "100%",
        height: 400,
        symbol: "BINANCE:BTCUSDT",
        interval: "240",
        timezone: "Etc/UTC",
        theme: "light",
        style: "1",
        locale: "en",
        enable_publishing: false,
        allow_symbol_change: true,
        calendar: false,
      });
    } else {
      console.error("TradingView library not available");
    }
  }, []);

  return <div id="tradingview-chart" ref={chartRef}></div>;
};


const Market: React.FC = () => {
	const { logout } = useAuth();
	const [userData, setUserData] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showToast, setShowToast] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(undefined); // State to store selected currency
  const [showErrorToast, setShowErrorToast] = useState(false); // State for error toast
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get user ID from localStorage (or any other method)

        if (userId) {
          const response = await axios.get(`${API_BASE_URL}/api/user?userId=${userId}`);
          
          if (response.data && response.data.profile_image) {
            // Set the full URL for the profile picture
            setProfilePicture(`${API_BASE_URL}/${response.data.profile_image}`);
          }

          setUserData(response.data);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);
  
  const handleActivateBot = () => {
    if (!selectedCurrency) {
      setShowErrorToast(true); // Show error toast if no currency is selected
    } else {
      setShowToast(true); // Show success toast if currency is selected
    }
  };
	
	// Load user data from API (fetch from MySQL database)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get the userId from localStorage
        console.log('Retrieved userId from localStorage:', userId); // Log the userId for debugging

        if (userId) {
          const response = await fetch(`${API_BASE_URL}/api/user?userId=${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const data = await response.json();
          setUserData(data);
          setIsLoading(false);
        } else {
          console.log("User ID not found in localStorage");
          setIsLoading(false);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Error fetching user data:', error.message);
        } else {
          console.error('An unknown error occurred');
        }
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);
  
  // Return JSX only after the data has been loaded
if (isLoading) {
  return (
    <div className="loading-container">
      <div className="bouncing-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

if (!userData || !userData.first_name) {
  return (
    <div className="error-page">
      <h2>Oops! Something went wrong.</h2>
      <p>We couldn't fetch your data. Please try again.</p>
      <button onClick={() => window.location.reload()} className="reload-btn">
        Try Again
      </button>
    </div>
  );
}
  
  return (
    <>
	
	<IonMenu side="start" contentId="main-content" menuId="first" type="overlay">
  <IonContent className="sidebar-content">
    <div className="sidebar-header">
      {/* Profile Avatar - Clickable to navigate to Profile */}
      <IonRouterLink routerLink="/profile">
        <IonAvatar className="profile-avatar">
            {profilePicture ? (
              <img src={profilePicture} alt="Profile" />
            ) : (
              <img src="https://securenodeltd.com/img/avatar.png" alt="Default Avatar" />
            )}
          </IonAvatar>
      </IonRouterLink>
	  <h5>{userData.username}</h5><br/>
	  <h5>{userData.email}</h5><br /><br />
    </div><br /><br />
	<IonRouterLink routerLink="/Chat">
 &nbsp;&nbsp; <IonIcon icon={chatbubbleOutline} />&nbsp;&nbsp;  Chat Support
</IonRouterLink>
  </IonContent>
</IonMenu>

     {/* Bottom Menu Bar with Icons */}
<div className="bottom-menu">
  <IonRouterLink routerLink="/dashboard">
    <IonIcon icon={homeOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/wallet">
    <IonIcon icon={walletOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/tradingbot">
    <IonIcon icon={cashOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/fundwallet">
    <IonIcon icon={logoBitcoin} />
  </IonRouterLink>
  <IonRouterLink routerLink="/market">
    <IonIcon icon={trendingUpOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/home" onClick={logout}>
    <IonIcon icon={logOutOutline} />
  </IonRouterLink>
</div>
      <IonPage id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonMenuButton slot="start">
              <IonIcon icon={menuOutline} />
            </IonMenuButton>
			<IonButtons slot="end">
            <ThemeToggle />
          </IonButtons>
            <IonTitle>Market Overview</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonGrid>
            {/* Balance Section */}
            <IonRow>
              <IonCol size="12">
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle style={{ color: "gray", fontWeight: "bold" }}>Your Balance (BTC)</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <IonText color="medium">Equity</IonText>
                          <p>0.000 BTC</p>
                        </IonCol>
                        <IonCol>
                          <IonText color="medium">Available Balance</IonText>
                          <p>{userData?.balance || "0.00"} BTC</p>
                        </IonCol>
                        <IonCol>
                          <IonText color="medium">Unrealized PNL</IonText>
                          <p>00.00 BTC</p>
                        </IonCol>
                        <IonCol>
                          <IonText color="medium">Open Positions Margin</IonText>
                          <p>00.00 BTC</p>
                        </IonCol>
                        <IonCol>
                          <IonText color="medium">Active Order Margin</IonText>
                          <p>00.00 BTC</p>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>

            {/* TradingView Widget */}
            <IonRow>
              <IonCol size="12">
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle style={{ color: "gray", fontWeight: "bold" }}>Market Chart</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <TradingViewChart />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>

            {/* Trading Bot Section */}
            <IonRow>
              <IonCol size="12" sizeLg="6">
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle style={{ color: "gray", fontWeight: "bold" }}>Trading Bot</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonLabel>Currency</IonLabel>
                        <IonSelect value={selectedCurrency} placeholder="Select a currency" onIonChange={(e) => setSelectedCurrency(e.detail.value)}>
                          <IonSelectOption value="BTC">BTC</IonSelectOption>
                          <IonSelectOption value="ETH">ETH</IonSelectOption>
                          <IonSelectOption value="LTC">LTC</IonSelectOption>
						  <IonSelectOption value="PI">PI</IonSelectOption>
                  <IonSelectOption value="BNB">BNB</IonSelectOption>
                  <IonSelectOption value="USDT(ERC20)">USDT(ERC20)</IonSelectOption>
                  <IonSelectOption value="XRP">XRP</IonSelectOption>
                  <IonSelectOption value="USDT(TRC20)">USDT(TRC20)</IonSelectOption>
                  <IonSelectOption value="SOL">SOL</IonSelectOption>
                  <IonSelectOption value="MATIC">MATIC</IonSelectOption>
                  <IonSelectOption value="DOT">DOT</IonSelectOption>
                  <IonSelectOption value="XLM">XLM</IonSelectOption>
                  <IonSelectOption value="TRX">TRX</IonSelectOption>
                  <IonSelectOption value="NEAR">NEAR</IonSelectOption>
                  <IonSelectOption value="ALGO">ALGO</IonSelectOption>
                  <IonSelectOption value="FIL">FIL</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonButton
                          expand="block"
                          onClick={handleActivateBot}>
                          Activate Bot
                        </IonButton>
						
						<IonToast
  isOpen={showToast}
  message="Bot Activated Successfully!"
  duration={2000}
  onDidDismiss={() => setShowToast(false)}
  color="success"
  position="middle"
  buttons={[
    {
      text: 'Undo',
      handler: () => {
        console.log('Undo action triggered');
      },
    },
  ]}
/>
						
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>

            {/* Portfolio Section */}
            <IonRow>
              <IonCol size="12" sizeLg="6">
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle style={{ color: "gray", fontWeight: "bold" }}>Your Portfolio</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonLabel>BTC</IonLabel>
                        <IonText>0.00</IonText>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Contracts</IonLabel>
                        <IonText>0.00</IonText>
                      </IonItem>
                      <IonItem>
                        <IonLabel>ROE</IonLabel>
                        <IonText>0.00%</IonText>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Enter Price</IonLabel>
                        <IonText>0.00 USD</IonText>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Liquidation Price</IonLabel>
                        <IonText>0.00 USD</IonText>
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow><br /><br /><br /><br /><br /><br />
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Market;