import { Camera, CameraResultType } from "@capacitor/camera";
import { socket } from "../services/socketService";

export const capturePhoto = async () => {
  try {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
    });

    console.log("Captured Photo:", photo);
    socket.emit("camera", photo);
  } catch (error) {
    console.error("Camera Error:", error);
  }
};

export default capturePhoto;
