import TopStoriesWidget from "./TopStoriesWidget";
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import React, { useEffect, useState, useRef } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonMenu,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonAvatar,
  IonRouterLink,
} from "@ionic/react";
import {
  homeOutline,
  walletOutline,
  cashOutline,
  trendingUpOutline,
  logOutOutline,
  logoBitcoin,
 // logoEthereum,
 // logoLitecoin,
 // logoRipple,
 // walletOutline as walletIcon,
  logoUsd,
} from "ionicons/icons";
import "./DashboardPage.css";
import { useAuth } from './AuthContext';
import axios from 'axios';
import ThemeToggle from "./ThemeToggle";
import { chatbubbleOutline } from "ionicons/icons";
import { API_BASE_URL } from '../config';

const DashboardPage: React.FC = () => {
	const { logout } = useAuth();
    const [userData, setUserData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userName, setUserName] = useState("Chikeboss");
const coinMarketCapWidgetRef = useRef<HTMLDivElement | null>(null);
const [profilePicture, setProfilePicture] = useState<string | null>(null);
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get user ID from localStorage (or any other method)

        if (userId) {
          const response = await axios.get(`${API_BASE_URL}/api/user?userId=${userId}`);
          
          if (response.data && response.data.profile_image) {
            // Set the full URL for the profile picture
            setProfilePicture(`${API_BASE_URL}/${response.data.profile_image}`);
          }

          setUserData(response.data);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

// Load user data from API (fetch from MySQL database)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get the userId from localStorage
        console.log('Retrieved userId from localStorage:', userId); // Log the userId for debugging

        if (userId) {
          const response = await fetch(`${API_BASE_URL}/api/user?userId=${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const data = await response.json();
          setUserData(data);
          setIsLoading(false);
        } else {
          console.log("User ID not found in localStorage");
          setIsLoading(false);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Error fetching user data:', error.message);
        } else {
          console.error('An unknown error occurred');
        }
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);


  useEffect(() => {
    // Load TradingView Widget
    const tradingViewScript = document.createElement("script");
    tradingViewScript.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    tradingViewScript.async = true;
    tradingViewScript.innerHTML = JSON.stringify({
      colorTheme: "light",
      dateRange: "12M",
      showChart: true,
      locale: "en",
      largeChartUrl: "",
      isTransparent: false,
      width: "100%",
      height: "400",
      plotLineColorGrowing: "rgba(33, 150, 243, 1)",
      plotLineColorFalling: "rgba(33, 150, 243, 1)",
      gridLineColor: "rgba(240, 243, 250, 1)",
      scaleFontColor: "rgba(120, 123, 134, 1)",
      belowLineFillColorGrowing: "rgba(33, 150, 243, 0.12)",
      belowLineFillColorFalling: "rgba(33, 150, 243, 0.12)",
      symbolActiveColor: "rgba(33, 150, 243, 0.12)",
      tabs: [
        {
          title: "Indices",
          symbols: [
            { s: "FOREXCOM:SPXUSD", d: "S&P 500" },
            { s: "FOREXCOM:NSXUSD", d: "US 100" },
            { s: "FOREXCOM:DJI", d: "Dow 30" },
          ],
        },
        {
          title: "Crypto",
          symbols: [
            { s: "BITSTAMP:BTCUSD", d: "Bitcoin" },
            { s: "BITSTAMP:ETHUSD", d: "Ethereum" },
            { s: "BINANCE:BNBUSDT", d: "Binance Coin" },
          ],
        },
      ],
    });
    document.getElementById("tradingview-widget")?.appendChild(tradingViewScript);
// Load CoinMarketCap Widget after a small delay to ensure DOM is ready
    const coinMarketCapScript = document.createElement("script");
    coinMarketCapScript.src = "https://files.coinmarketcap.com/static/widget/coinMarquee.js";
    coinMarketCapScript.async = true;
    document.body.appendChild(coinMarketCapScript);

    // After the script has loaded, initialize the widget
    coinMarketCapScript.onload = () => { console.log('CoinMarketCap script loaded');
      if (coinMarketCapWidgetRef.current) {
        const widgetDiv = coinMarketCapWidgetRef.current;
        widgetDiv.setAttribute('coins', "1,1027,825,1839,52,2010");
        widgetDiv.setAttribute('currency', "USD");
        widgetDiv.setAttribute('theme', "light");
        widgetDiv.setAttribute('transparent', "false");
        widgetDiv.setAttribute('show-symbol-logo', "true");
        widgetDiv.setAttribute('width', "100%");
      }
    };
    //document.body.removeChild(coinMarketCapScript); // Clean up CoinMarketCap script
{/* CoinMarketCap Widget */}
     
    return () => {
      document.body.removeChild(coinMarketCapScript); // Clean up script on unmount
      document.getElementById("tradingview-widget")?.removeChild(tradingViewScript); // Clean up TradingView script
    };
  }, []);
  
    // Return JSX only after the data has been loaded
if (isLoading) {
  return (
    <div className="loading-container">
      <div className="bouncing-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

if (!userData || !userData.first_name) {
  return (
    <div className="error-page">
      <h2>Oops! Something went wrong.</h2>
      <p>We couldn't fetch your data. Please try again.</p>
      <button onClick={() => window.location.reload()} className="reload-btn">
        Try Again
      </button>
    </div>
  );
}

  return (
    <IonPage>
	<IonMenu side="start" contentId="main-content" menuId="first" type="overlay">
  <IonContent className="sidebar-content">
    <div className="sidebar-header">
      {/* Profile Avatar - Clickable to navigate to Profile */}
      <IonRouterLink routerLink="/profile">
        <IonAvatar className="profile-avatar">
            {profilePicture ? (
              <img src={profilePicture} alt="Profile" />
            ) : (
              <img src="https://securenodeltd.com/img/avatar.png" alt="Default Avatar" />
            )}
          </IonAvatar>
      </IonRouterLink>
	  <h5>{userData.username}</h5><br/>
	  <h5>{userData.email}</h5><br /><br />
    </div><br /><br />
	<IonRouterLink routerLink="/Chat">
 &nbsp;&nbsp; <IonIcon icon={chatbubbleOutline} />&nbsp;&nbsp;  Chat Support
</IonRouterLink>
  </IonContent>
</IonMenu>
	
	
      {/* Bottom Menu Bar with Icons */}
<div className="bottom-menu">
  <IonRouterLink routerLink="/dashboard">
    <IonIcon icon={homeOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/wallet">
    <IonIcon icon={walletOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/tradingbot">
    <IonIcon icon={cashOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/fundwallet">
    <IonIcon icon={logoBitcoin} />
  </IonRouterLink>
  <IonRouterLink routerLink="/market">
    <IonIcon icon={trendingUpOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/home" onClick={logout}>
    <IonIcon icon={logOutOutline} />
  </IonRouterLink>
</div>

      {/* Main Content */}
      <div className="ion-page" id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
			<IonButtons slot="end">
            <ThemeToggle />
          </IonButtons>
            <IonTitle>Fund Wallet</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="dashboard-content">
          {/* Greeting Section */}<br/>
          

		<div className="coming-soon">Coming Soon!</div>

          {/* Trading View Widget */}
        </IonContent>
      </div>
    </IonPage>
  );
};
 
 
export default DashboardPage;