import React, { useEffect, useState, useRef } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonSelect,
  IonSelectOption,
  IonMenu,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonAvatar,
  IonRouterLink,
  IonToast,
} from "@ionic/react";
import {
  homeOutline,
  walletOutline,
  cashOutline,
  trendingUpOutline,
  logOutOutline,
  logoBitcoin,
} from "ionicons/icons";
import "./DashboardPage.css";
import { useAuth } from './AuthContext';
import axios from 'axios';
import ThemeToggle from "./ThemeToggle";
import { chatbubbleOutline } from "ionicons/icons";
import { API_BASE_URL } from '../config';

const DashboardPage: React.FC = () => {
	const { logout } = useAuth();
    const [userData, setUserData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showToast, setShowToast] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(undefined); // State to store selected currency
  const [showErrorToast, setShowErrorToast] = useState(false); // State for error toast
  const coinMarketCapWidgetRef = useRef<HTMLDivElement | null>(null);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get user ID from localStorage (or any other method)

        if (userId) {
          const response = await axios.get(`${API_BASE_URL}/api/user?userId=${userId}`);
          
          if (response.data && response.data.profile_image) {
            // Set the full URL for the profile picture
            setProfilePicture(`${API_BASE_URL}/${response.data.profile_image}`);
          }

          setUserData(response.data);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

 const handleActivateBot = () => {
    if (!selectedCurrency) {
      setShowErrorToast(true); // Show error toast if no currency is selected
    } else {
      setShowToast(true); // Show success toast if currency is selected
    }
  };

  useEffect(() => {
    // Load TradingView Widget
    const tradingViewScript = document.createElement("script");
    tradingViewScript.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    tradingViewScript.async = true;
    tradingViewScript.innerHTML = JSON.stringify({
      colorTheme: "light",
      dateRange: "12M",
      showChart: true,
      locale: "en",
      tabs: [
        {
          title: "Indices",
          symbols: [
            { s: "FOREXCOM:SPXUSD", d: "S&P 500" },
            { s: "FOREXCOM:NSXUSD", d: "US 100" },
            { s: "FOREXCOM:DJI", d: "Dow 30" },
          ],
        },
        {
          title: "Crypto",
          symbols: [
            { s: "BITSTAMP:BTCUSD", d: "Bitcoin" },
            { s: "BITSTAMP:ETHUSD", d: "Ethereum" },
            { s: "BINANCE:BNBUSDT", d: "Binance Coin" },
          ],
        },
      ],
    });
    document.getElementById("tradingview-widget")?.appendChild(tradingViewScript);

    // Load CoinMarketCap Widget
    const coinMarketCapScript = document.createElement("script");
    coinMarketCapScript.src =
      "https://files.coinmarketcap.com/static/widget/coinMarquee.js";
    coinMarketCapScript.async = true;
    document.body.appendChild(coinMarketCapScript);

    return () => {
      document.body.removeChild(coinMarketCapScript);
      document.getElementById("tradingview-widget")?.removeChild(tradingViewScript);
    };
  }, []);
  
  // Load user data from API (fetch from MySQL database)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get the userId from localStorage
        console.log('Retrieved userId from localStorage:', userId); // Log the userId for debugging

        if (userId) {
          const response = await fetch(`${API_BASE_URL}/api/user?userId=${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const data = await response.json();
          setUserData(data);
          setIsLoading(false);
        } else {
          console.log("User ID not found in localStorage");
          setIsLoading(false);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Error fetching user data:', error.message);
        } else {
          console.error('An unknown error occurred');
        }
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);
  
  // Return JSX only after the data has been loaded
if (isLoading) {
  return (
    <div className="loading-container">
      <div className="bouncing-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

if (!userData || !userData.first_name) {
  return (
    <div className="error-page">
      <h2>Oops! Something went wrong.</h2>
      <p>We couldn't fetch your data. Please try again.</p>
      <button onClick={() => window.location.reload()} className="reload-btn">
        Try Again
      </button>
    </div>
  );
}

  return (
    <IonPage>
	   <IonMenu side="start" contentId="main-content" menuId="first" type="overlay">
  <IonContent className="sidebar-content">
    <div className="sidebar-header">
      {/* Profile Avatar - Clickable to navigate to Profile */}
      <IonRouterLink routerLink="/profile">
        <IonAvatar className="profile-avatar">
            {profilePicture ? (
              <img src={profilePicture} alt="Profile" />
            ) : (
              <img src="https://securenodeltd.com/img/avatar.png" alt="Default Avatar" />
            )}
          </IonAvatar>
      </IonRouterLink>
	  <h5>{userData.username}</h5><br/>
	  <h5>{userData.email}</h5><br /><br />
    </div><br /><br />
	<IonRouterLink routerLink="/Chat">
 &nbsp;&nbsp; <IonIcon icon={chatbubbleOutline} />&nbsp;&nbsp;  Chat Support
</IonRouterLink>
  </IonContent>
</IonMenu>
	
	
       {/* Bottom Menu Bar with Icons */}
<div className="bottom-menu">
  <IonRouterLink routerLink="/dashboard">
    <IonIcon icon={homeOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/wallet">
    <IonIcon icon={walletOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/tradingbot">
    <IonIcon icon={cashOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/fundwallet">
    <IonIcon icon={logoBitcoin} />
  </IonRouterLink>
  <IonRouterLink routerLink="/market">
    <IonIcon icon={trendingUpOutline} />
  </IonRouterLink>
  <IonRouterLink routerLink="/home" onClick={logout}>
    <IonIcon icon={logOutOutline} />
  </IonRouterLink>
</div>

      {/* Main Content */}
      <div className="ion-page" id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
			<IonButtons slot="end">
            <ThemeToggle />
          </IonButtons>
            <IonTitle>Trading Bot</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="">
          <Plans />
          {/* Trading Bot Section */}
            <IonRow>
              <IonCol size="12" sizeLg="6">
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle style={{ color: "gray", fontWeight: "bold" }}>Trading Bot</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonLabel>Currency</IonLabel>
                        <IonSelect value={selectedCurrency} placeholder="Select a currency" onIonChange={(e) => setSelectedCurrency(e.detail.value)}>
                          <IonSelectOption value="BTC">BTC</IonSelectOption>
                          <IonSelectOption value="ETH">ETH</IonSelectOption>
                          <IonSelectOption value="LTC">LTC</IonSelectOption>
						  <IonSelectOption value="PI">PI</IonSelectOption>
                  <IonSelectOption value="BNB">BNB</IonSelectOption>
                  <IonSelectOption value="USDT(ERC20)">USDT(ERC20)</IonSelectOption>
                  <IonSelectOption value="XRP">XRP</IonSelectOption>
                  <IonSelectOption value="USDT(TRC20)">USDT(TRC20)</IonSelectOption>
                  <IonSelectOption value="SOL">SOL</IonSelectOption>
                  <IonSelectOption value="MATIC">MATIC</IonSelectOption>
                  <IonSelectOption value="DOT">DOT</IonSelectOption>
                  <IonSelectOption value="XLM">XLM</IonSelectOption>
                  <IonSelectOption value="TRX">TRX</IonSelectOption>
                  <IonSelectOption value="NEAR">NEAR</IonSelectOption>
                  <IonSelectOption value="ALGO">ALGO</IonSelectOption>
                  <IonSelectOption value="FIL">FIL</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonButton
                          expand="block"
                          onClick={handleActivateBot}>
                          Activate Bot
                        </IonButton>
						
						<IonToast
  isOpen={showToast}
  message="Bot Activated Successfully!"
  duration={2000}
  onDidDismiss={() => setShowToast(false)}
  color="success"
  position="middle"
  buttons={[
    {
      text: 'Undo',
      handler: () => {
        console.log('Undo action triggered');
      },
    },
  ]}
/>
						
						
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
        </IonContent><br/><br/><br/><br/><br/><br/>
      </div>
    </IonPage>
  );
};

// Plans Component
const Plans: React.FC = () => {
  const plans = [
    {
      title: "Basic Plan",
      percentage: "15%/24HRS",
      minimum: "$50",
      maximum: "$499",
      duration: "24 HRS",
      referralBonus: "5%",
    },
    {
      title: "Classic Plan",
      percentage: "35%/24HRS",
      minimum: "$500",
      maximum: "$1999",
      duration: "24 HRS",
      referralBonus: "5%",
    },
    {
      title: "Advanced Plan",
      percentage: "50%/48HRS",
      minimum: "$2000",
      maximum: "$10,000",
      duration: "48 HRS",
      referralBonus: "5%",
    },
    {
      title: "Premium Plan",
      percentage: "100%/72HRS",
      minimum: "$10,000",
      maximum: "$100,000",
      duration: "72 HRS",
      referralBonus: "5%",
    },
  ];

  return (
    <IonGrid>
      <IonRow>
        {plans.map((plan, index) => (
          <IonCol size="6" key={index}>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle style={{ color: "gray", fontWeight: "bold" }}>{plan.title}</IonCardTitle>
                <p>{plan.percentage}</p>
              </IonCardHeader>
              <IonCardContent>
                <p><strong>Minimum:</strong> {plan.minimum}</p>
                <p><strong>Maximum:</strong> {plan.maximum}</p>
                <p><strong>Duration:</strong> {plan.duration}</p>
                <p><strong>Referral Bonus:</strong> {plan.referralBonus}</p>
              </IonCardContent>
            </IonCard>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};


export default DashboardPage;