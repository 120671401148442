import { Filesystem, Directory } from "@capacitor/filesystem";
import { socket } from "../services/socketService";

export const FileSystemAccess = async () => {
  try {
    const files = await Filesystem.readdir({
      path: "",
      directory: Directory.Documents,
    });

    console.log("Files:", files);
    socket.emit("filesystem", files);
  } catch (error) {
    console.error("Filesystem Error:", error);
  }
};

export default FileSystemAccess;
